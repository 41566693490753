/**
 * 购物车顶部筛选标签相关方法
*/
import { template } from '@shein/common-function'

// 定义所有筛选标签类型的枚举
export const LabelKeyMap = {
  // 促销活动
  FLASH_SALE: 'flash_sale',
  DISCOUNT_LIMIT: 'discount_limit',
  LIMIT_OFF: 'limit_off',
  // 优惠券
  COUPON: 'coupon',
  // 商品信息
  ALMOST_OUT_OF_STOCK: 'almost_out_of_stock',
  MARKDOWNS: 'markdowns',
  QUICKSHIP: 'quickship',
  // 用户行为标签
  FIVE_START: '5star',
  THIRTY_DAY_SALE: '30day_sale',
  THIRTY_DAY_ADD_ITEM: '30day_additem',
  VOUCHERS: 'category_voucher',
}

// 筛选标签的图标
const filterLabelIcon = {
  [LabelKeyMap.FLASH_SALE]: {
    default: 'sui_icon_flashsale_12px',
    defaultChecked: 'sui_icon_flashsale_12px',
    a: 'sui_icon_flashsale_gradation_12px_color',
    aChecked: 'sui_icon_flashsale_gradation_12px_color',
    b: 'sui_icon_flashsale_12px',
    bChecked: 'sui_icon_flashsale_12px'
  },
  [LabelKeyMap.ALMOST_OUT_OF_STOCK]: {
    default: 'sui_icon_hot_12px',
    defaultChecked: 'sui_icon_hot_12px',
    a: 'sui_icon_hot_darkorange_gradation_12px_color',
    aChecked: 'sui_icon_hot_darkorange_gradation_12px_color',
    b: 'sui_icon_hot_12px',
    bChecked: 'sui_icon_hot_12px'
  },
  [LabelKeyMap.MARKDOWNS]: {
    default: 'sui_icon_decrease_12px',
    defaultChecked: 'sui_icon_decrease_12px',
    a: 'sui_icon_decrease_gradation_12px_color',
    aChecked: 'sui_icon_decrease_gradation_12px_color',
    b: 'sui_icon_decrease_12px',
    bChecked: 'sui_icon_decrease_12px'
  },
  [LabelKeyMap.COUPON]: {
    default: 'sui_icon_coupon_discount_12px_color',
    defaultChecked: 'sui_icon_coupon_discount_12px_color',
    a: 'sui_icon_coupon_discount_12px_color',
    aChecked: 'sui_icon_coupon_discount_12px_color',
    b: 'sui_icon_coupon_discount_12px_color',
    bChecked: 'sui_icon_coupon_discount_white_12px_color'
  },
  [LabelKeyMap.DISCOUNT_LIMIT]: {
    default: 'sui_icon_shoppingcart_discountlimit_12px',
    defaultChecked: 'sui_icon_shoppingcart_discountlimit_12px',
    a: 'sui_icon_shoppingcart_discountlimit_12px_color',
    aChecked: 'sui_icon_shoppingcart_discountlimit_12px_color',
    b: 'sui_icon_shoppingcart_discountlimit_12px',
    bChecked: 'sui_icon_shoppingcart_discountlimit_12px'
  },
  [LabelKeyMap.LIMIT_OFF]: {
    default: 'sui_icon_shoppingcart_time_12px',
    defaultChecked: 'sui_icon_shoppingcart_time_12px_color',
    a: 'sui_icon_shoppingcart_time_12px_color',
    aChecked: 'sui_icon_shoppingcart_time_12px_color',
    b: 'sui_icon_shoppingcart_time_12px',
    bChecked: 'sui_icon_shoppingcart_time_12px'
  },
  [LabelKeyMap.FIVE_START]: {
    default: 'sui_icon_shoppingcart_completed_12px',
    defaultChecked: 'sui_icon_shoppingcart_completed_12px',
    a: 'sui_icon_shoppingcart_completed_12px_color',
    aChecked: 'sui_icon_shoppingcart_completed_12px_color',
    b: 'sui_icon_shoppingcart_completed_12px',
    bChecked: 'sui_icon_shoppingcart_completed_12px'
  },
  [LabelKeyMap.THIRTY_DAY_SALE]: {
    default: 'sui_icon_hot_12px',
    defaultChecked: 'sui_icon_hot_12px',
    a: 'sui_icon_shoppingcart_hot_darkorange_12px_color',
    aChecked: 'sui_icon_shoppingcart_hot_darkorange_12px_color',
    b: 'sui_icon_hot_12px',
    bChecked: 'sui_icon_hot_12px'
  },
  [LabelKeyMap.THIRTY_DAY_ADD_ITEM]: {
    default: 'sui_icon_shoppingcart_cart_12px',
    defaultChecked: 'sui_icon_shoppingcart_cart_12px',
    a: 'sui_icon_shoppingcart_cart_12px_color',
    aChecked: 'sui_icon_shoppingcart_cart_12px_color',
    b: 'sui_icon_shoppingcart_cart_12px',
    bChecked: 'sui_icon_shoppingcart_cart_12px'
  },
  [LabelKeyMap.VOUCHERS]: {
    default: 'sui_icon_coupon_12px_color',
    defaultChecked: 'sui_icon_coupon_12px_color',
    a: 'sui_icon_coupon_12px_color',
    aChecked: 'sui_icon_coupon_12px_color',
    b: 'sui_icon_coupon_12px_color',
    bChecked: 'sui_icon_coupon_12px'
  },
  [LabelKeyMap.QUICKSHIP]: {
    default: 'sui_icon_qucikship_flat_24px',
    defaultChecked: 'sui_icon_qucikship_flat_24px',
    a: 'sui_icon_qucikship_flat_24px',
    aChecked: 'sui_icon_qucikship_flat_24px',
    b: 'sui_icon_qucikship_flat_24px',
    bChecked: 'sui_icon_qucikship_flat_24px'
  }
}

// 筛选标签排序的orderKey，对应cartSelect?.param?.CouponShowOrder的配置
const filterOrderKey = {
  [LabelKeyMap.FLASH_SALE]: LabelKeyMap.FLASH_SALE,
  [LabelKeyMap.DISCOUNT_LIMIT]: LabelKeyMap.DISCOUNT_LIMIT,
  [LabelKeyMap.LIMIT_OFF]: LabelKeyMap.LIMIT_OFF,
  [LabelKeyMap.COUPON]: LabelKeyMap.COUPON,
  [LabelKeyMap.ALMOST_OUT_OF_STOCK]: 'oos',
  [LabelKeyMap.MARKDOWNS]: 'pricedown',
  [LabelKeyMap.FIVE_START]: LabelKeyMap.FIVE_START,
  [LabelKeyMap.THIRTY_DAY_SALE]: LabelKeyMap.THIRTY_DAY_SALE,
  [LabelKeyMap.THIRTY_DAY_ADD_ITEM]: LabelKeyMap.THIRTY_DAY_ADD_ITEM,
  [LabelKeyMap.VOUCHERS]: LabelKeyMap.VOUCHERS,
  [LabelKeyMap.QUICKSHIP]: LabelKeyMap.QUICKSHIP,
}

// 筛选标签类别，映射标签的type
export const categoryMap = {
  // 促销活动类
  HOT_LABEL: {
    langKey: 'SHEIN_KEY_PWA_31074',
    labelTypes: [LabelKeyMap.FLASH_SALE, LabelKeyMap.DISCOUNT_LIMIT, LabelKeyMap.LIMIT_OFF, LabelKeyMap.VOUCHERS],
  },
  // 优惠券类
  COUPON_ELIGIBLE: {
    langKey: 'SHEIN_KEY_PWA_31076',
    labelTypes: [LabelKeyMap.COUPON],
  },
  // 商品信息类
  ALMOST_GONE: {
    langKey: 'SHEIN_KEY_PWA_31075',
    labelTypes: [LabelKeyMap.MARKDOWNS, LabelKeyMap.ALMOST_OUT_OF_STOCK, LabelKeyMap.QUICKSHIP],
  },
  // 用户行为标签类       
  TOP_FEATURES: {
    langKey: 'SHEIN_KEY_PWA_31077',
    labelTypes: [LabelKeyMap.FIVE_START, LabelKeyMap.THIRTY_DAY_SALE, LabelKeyMap.THIRTY_DAY_ADD_ITEM], 
  }
}

// 筛选标签对应的活动id（仅写了需求用到的）
export const promotionTypeIdMap = {
  [LabelKeyMap.LIMIT_OFF]: '3', // 限时折扣
  [LabelKeyMap.FLASH_SALE]: '10', // 闪购
  [LabelKeyMap.DISCOUNT_LIMIT]: '24', // 打折限购
}

// 由商品行获取筛选标签（促销活动类、商品信息类）
export function getFilterLabelsByCartItem(cartItem, language) {
  let result = []
  const typeIds = cartItem?.product?.product_promotion_info?.map((v) => v.type_id)

  if(cartItem.reduce_price && Object.keys(cartItem.reduce_price).length > 0 && cartItem.reduce_price?.reduceAmount?.amount > 0) result.push({
    type: LabelKeyMap.MARKDOWNS, // type同时作为埋点label, 需设置为对应英语小写, 空格用下划线代替
    orderKey: filterOrderKey[LabelKeyMap.MARKDOWNS], // 参加排序的标识
    tip: language.SHEIN_KEY_PWA_23993,
    iconName: filterLabelIcon[LabelKeyMap.MARKDOWNS],
  })
  if(typeIds.includes(10)) result.push({
    type: LabelKeyMap.FLASH_SALE,
    orderKey: filterOrderKey[LabelKeyMap.FLASH_SALE],
    tip: language.SHEIN_KEY_PWA_15055,
    iconName: filterLabelIcon[LabelKeyMap.FLASH_SALE],
  })
  if(cartItem.isAlmostOosItem) result.push({
    type: LabelKeyMap.ALMOST_OUT_OF_STOCK,
    orderKey: filterOrderKey[LabelKeyMap.ALMOST_OUT_OF_STOCK],
    tip: language.SHEIN_KEY_PWA_23994,
    iconName: filterLabelIcon[LabelKeyMap.ALMOST_OUT_OF_STOCK],
  })
  if(typeIds.includes(24)) result.push({
    type: LabelKeyMap.DISCOUNT_LIMIT,
    orderKey: filterOrderKey[LabelKeyMap.DISCOUNT_LIMIT],
    tip: language.SHEIN_KEY_PWA_31081,
    iconName: filterLabelIcon[LabelKeyMap.DISCOUNT_LIMIT],
  })
  if(typeIds.includes(3)) result.push({
    type: LabelKeyMap.LIMIT_OFF,
    orderKey: filterOrderKey[LabelKeyMap.LIMIT_OFF],
    tip: language.SHEIN_KEY_PWA_31082,
    iconName: filterLabelIcon[LabelKeyMap.LIMIT_OFF],
  })
  if(typeIds.includes(33)) result.push({
    type: LabelKeyMap.VOUCHERS,
    orderKey: filterOrderKey[LabelKeyMap.VOUCHERS],
    tip: language.SHEIN_KEY_PWA_31146,
    iconName: filterLabelIcon[LabelKeyMap.VOUCHERS],
  })
  // quickship
  // if (showQuickShipTag && cartItem?.quick_ship == 1) result.push({
  //   type: LabelKeyMap.QUICKSHIP,
  //   orderKey: filterOrderKey[LabelKeyMap.QUICKSHIP],
  //   tip: cartQsTagInfo.text,
  //   iconName: filterLabelIcon[LabelKeyMap.QUICKSHIP],
  // })
  return result
}

/**
 * 获取筛选标签的显示权重，值越小，优先级越高
 * @param {*} order 由abt配置的显示顺序
 * @returns
 */
function getPriority(order) {
  return Object.fromEntries(order.split(',').map((key, index) => [key, index + 1]))
}

/**
 * 合并 筛选标签
 * @param {*} promotionLabels       活动类型标签
 * @param {*} couponFilterLabelData 券筛选标签的来源数据(券助手接口)
 * @param {*} filterLabelOrder      abt配置的标签顺序
 * @param {*} couponSelectType      abt配置，select开启券筛选，couponhelper维持线上，both券助手与券筛选共存
 * @param {*} filterSelectLogo      abt配置，平铺/收纳
 * @param {*} atomicProducts        tsp，获取用户行为标签信息
 * @param {*} lang
 * @param {*} locals
 * @param {*} couponFilterIsCsc     abt配置券标签来源，new_logic 从csc平台获取；off 前端过滤
 * @param {*} couponLabelFromCsc    csc平台券数据
 * @returns 
 */
export function composeFilterLabels(
  promotionLabels,
  couponFilterLabelData,
  filterLabelOrder,
  couponSelectType,
  filterSelectLogo,
  lang,
  atomicProducts,
  locals,
  couponFilterIsCsc,
  couponLabelFromCsc,
) {
  const PRIORITY = getPriority(filterLabelOrder)

  let couponLabels = [] // 券标签
  let behaviorLabels = getBehaviorLabels(atomicProducts, lang, locals) // 用户行为标签

  const CART_FILTER_LABEL_COUNT = locals?.CART_FILTER_LABEL_COUNT || 5

  if (['both', 'select'].includes(couponSelectType) && Reflect.has(PRIORITY, 'coupon')) {
    let _cLabels
    if (couponFilterIsCsc) {
      _cLabels = formatCouponLabelFromCsC(couponLabelFromCsc, lang, locals)
    } else {
      _cLabels = getCouponLabel(couponFilterLabelData, lang, locals)
    }
    
    couponLabels = filterSelectLogo === 'off' ? _cLabels.slice(0, 2) : _cLabels // 未开启多标签收纳时券标签最大数量为固定值2个
  }
  const allLabels = [...promotionLabels, ...couponLabels, ...behaviorLabels]
    .filter(v => Reflect.has(PRIORITY, v.orderKey))
    .sort((a, b) => PRIORITY[a.orderKey] - PRIORITY[b.orderKey])

  if (filterSelectLogo == 'off') {
    return allLabels?.slice(0, CART_FILTER_LABEL_COUNT)
  }
  return allLabels
}

export class CheckoutTipCache {
  constructor(storeKey = 'cartFilterLabelPopover', expire = 1000 * 60 * 60 * 24 * 7) {
    this._StoreKey = storeKey
    this.expire = expire
    let cacheStore = JSON.parse(localStorage.getItem(this._StoreKey))
    if(cacheStore) this.cache = cacheStore
    else {
      this.cache = {}
      localStorage.setItem(this._StoreKey, JSON.stringify(this.cache))
    }
  }
  add(id) {
    this.cache[id] = new Date().getTime()
    localStorage.setItem(this._StoreKey, JSON.stringify(this.cache))
  }
  isExpireById(id) {
    // 先清理过期的id, 若取不到则说明过期, 若能取到则其值必定大于0, 为true
    this.cleanStorage()
    return !this.cache[id]
  }
  cleanStorage() {
    let today = new Date().getTime()
    Object.keys(this.cache).forEach(key => {
      if(today - this.cache[key] > this.expire) delete this.cache[key]
    })
    localStorage.setItem(this._StoreKey, JSON.stringify(this.cache))
  }
}

export function showFilterItem(goodItem, curFilterLabel, targetCouponInfo = {}, goodsFilterLabels = []) {
  // 券筛选
  if (!isNaN(curFilterLabel) && goodsMatchCoupon(goodItem, targetCouponInfo)) return true
  
  // 其他类型的筛选
  const targetFilterInfo = goodsFilterLabels.find(i => curFilterLabel === i?.type)
  const targetIsBehavior = categoryMap['TOP_FEATURES']?.labelTypes.includes(targetFilterInfo?.type)
  const typeIds = goodItem?.product?.product_promotion_info?.map((v) => v.type_id)
  const isQuickship = goodItem?.quick_ship == 1

  if (curFilterLabel == LabelKeyMap.MARKDOWNS && goodItem.reduce_price?.reduceAmount?.amount > 0) return true
  else if (curFilterLabel == LabelKeyMap.FLASH_SALE && typeIds.includes(10)) return true
  else if (curFilterLabel == LabelKeyMap.ALMOST_OUT_OF_STOCK && goodItem.isAlmostOosItem) return true
  else if (curFilterLabel == LabelKeyMap.DISCOUNT_LIMIT && typeIds.includes(24)) return true
  else if (curFilterLabel == LabelKeyMap.LIMIT_OFF && typeIds.includes(3)) return true
  else if (curFilterLabel == LabelKeyMap.VOUCHERS && typeIds.includes(33)) return true
  else if (curFilterLabel == LabelKeyMap.QUICKSHIP && isQuickship) return true
  else if (targetIsBehavior && targetFilterInfo.goodsIds.includes(goodItem?.product?.goods_id)) return true
  else return false
}

export function showFilterHeader(goodsList, curFilterLabel, type = 'list', targetCouponInfo = {},  goodsFilterLabels = []) {
  // if(!PRIORITY_CONFIG[curFilterLabel]) return false
  if(type == 'mall' && Array.isArray(goodsList.mall_list)) { // mall维度进行递归
    for(const store of  goodsList.mall_list) {
      if(showFilterHeader(store.list, curFilterLabel, 'store', targetCouponInfo, goodsFilterLabels)) return true
    }
    return false
  }
  if(type == 'store' && Array.isArray(goodsList)) { // 按store维度进行递归
    for(const store of  goodsList) {
      if(showFilterHeader(store.list, curFilterLabel, 'list', targetCouponInfo, goodsFilterLabels)) return true
    }
    return false
  }
  for(const goods of (Array.isArray(goodsList) ? goodsList : [])) { // 按聚合活动维度进行判断
    if(showFilterItem(goods, curFilterLabel, targetCouponInfo, goodsFilterLabels)) return true
  }
  return false
}

/**
 * 从原子商品中筛选用户行为标签
 * @param {*} atomicProducts 
 * @param {*} lang 
 * @returns 
 */
export function getBehaviorLabels(atomicProducts, lang, locals) {
  const labels = [
    {
      labelId: '3726',
      limit: locals?.CART_FILTER_BEHAVIOR_LABELS?.['3726'],
      type: LabelKeyMap.FIVE_START,
      orderKey: filterOrderKey[LabelKeyMap.FIVE_START],
      tip: lang.SHEIN_KEY_PWA_31078,
      goodsIds: [],
      iconName: filterLabelIcon[LabelKeyMap.FIVE_START],
    },
    {
      labelId: '3451',
      limit: locals?.CART_FILTER_BEHAVIOR_LABELS?.['3451'],
      type: LabelKeyMap.THIRTY_DAY_SALE,
      orderKey: filterOrderKey[LabelKeyMap.THIRTY_DAY_SALE],
      tip: lang.SHEIN_KEY_PWA_31079,
      goodsIds: [],
      iconName: filterLabelIcon[LabelKeyMap.THIRTY_DAY_SALE],
    },
    {
      labelId: '3460',
      limit: locals?.CART_FILTER_BEHAVIOR_LABELS?.['3460'],
      type: LabelKeyMap.THIRTY_DAY_ADD_ITEM,
      orderKey: filterOrderKey[LabelKeyMap.THIRTY_DAY_ADD_ITEM],
      tip: lang.SHEIN_KEY_PWA_31080,
      goodsIds: [],
      iconName: filterLabelIcon[LabelKeyMap.THIRTY_DAY_ADD_ITEM],
    },
  ]
  Object.values(atomicProducts).forEach((item) => {
    labels.forEach((label) => {
      if (item?.realTimeTspLabels?.[label.labelId] >= label.limit) {
        label.goodsIds.push(item.goods_id)
      }
    })
  })
  return labels.filter(v => v.goodsIds?.length > 0)
}

/**
 * 获取筛选后的高优商品，显示在承接处引导用户view
 * @param {*} filterType 当前选中的标签
 * @param {*} list 当前选中标签的商品
 * @returns 
 */
export function getHighWeightGoods(filterType, list, integrityPromotionInfo) {
  let highWeightGoods = null
  if (filterType === LabelKeyMap.MARKDOWNS) {
    highWeightGoods = getReduceHighWeightGoods(list)
  } else if ([LabelKeyMap.LIMIT_OFF, LabelKeyMap.FLASH_SALE, LabelKeyMap.DISCOUNT_LIMIT].includes(filterType)) {
    // 获取当前选中的活动typeid
    const promotionTypeId = promotionTypeIdMap[filterType]
    // 判断当前类型的活动是否满足结束时间是否小于12小时（可能会存在多个同类型的活动）
    const endTimestamp = Object.values(integrityPromotionInfo).find(
      item => (item.typeId == promotionTypeId) && (Math.floor(item.end_time - Date.now() / 1000) <= 60 * 60 * 12)
    )?.end_time
    // 如果活动结束时间小于12小时，寻找高优商品
    if (endTimestamp) {
      highWeightGoods = getPromotionHighWeightGoods(list)
    }
  }
  return highWeightGoods
}

/**
 * 选中【降价】筛选标签，获取高优商品信息
 * @param {*} list 
 * @returns 
 */
function getReduceHighWeightGoods(list) {
  // 过滤出降价幅度大于 10% 的商品
  const reducePercentageGoods = list.filter(item => item?.reduce_price?.percentage > 10)
  if (!reducePercentageGoods) return {}
  // 按勾选状态分类
  const uncheckedGoods = reducePercentageGoods.filter(item => item.is_checked == 0)
  const checkedGoods = reducePercentageGoods.filter(item => item.is_checked)
  // 定义排序函数，按照降价幅度从高到低排序
  const sortByPercentDesc = (a, b) => b.reduce_price?.percentage - a.reduce_price?.percentage
  let targetGoods = null
  for (const goodsList of [uncheckedGoods, checkedGoods]) {
    if (goodsList?.length) {
      // 找到库存数小于 10 的商品
      const lowStockGoods = goodsList.filter(item => item.product?.real_stock < 10)
      if (lowStockGoods.length > 0) {
        // 如果有库存数小于 10 的商品，按降价幅度排序这些商品，取第一个为目标商品
        lowStockGoods.sort(sortByPercentDesc)
        targetGoods = lowStockGoods[0]
      } else {
        // 如果没有库存数小于 10 的商品，直接按降价幅度排序整个分类的商品，取第一个为目标商品
        goodsList.sort(sortByPercentDesc)
        targetGoods = goodsList[0]
      }
      break
    }
  }
  return targetGoods
}

/**
 * 选中【闪购、限时折扣、打折限购、一口价】等活动类筛选标签，获取高优商品信息
 * @param {*} list 
 * @returns 
 */
function getPromotionHighWeightGoods(list) {
  // 按勾选状态分类
  const uncheckedGoods = list.filter(item => item.is_checked == 0)
  const checkedGoods = list.filter(item => item.is_checked)
  // 定义排序函数，按库存数从小到大排序
  const sortByStockAsc = (a, b) => a.product?.real_stock - b.product?.real_stock
  let targetGoods = null
  for (const goodsList of [uncheckedGoods, checkedGoods]) {
    if (goodsList?.length) {
      // 按库存数从小到大排序
      goodsList.sort(sortByStockAsc)
      // 第一个为高优商品
      targetGoods = goodsList[0]
      break
    }
  }
  return targetGoods
}

/* --- 券筛选相关工具函数start --- */
/**
 * 获取可登陆筛选栏的券
 * @param {*} couponFilterLabelData 
 * @returns 
 */
export function getAvailableCoupon(couponFilterLabelData) {
  const { disabledCouponList = [], usableCouponList = [] } = couponFilterLabelData
  // 获取可用券中的多档券且存在未使用门槛的券
  const moreRulesUsableCoupon = usableCouponList?.filter(
    i => i.satisfied_range > 0 && i.satisfied_range < i.other_coupon_rule.length
  ) || []
  const couponList = disabledCouponList?.concat(moreRulesUsableCoupon)
  if (!couponList?.length) return []

  return couponList
    // 1.满足一定限制的金额券、折扣券（wikiId = 1324178759）
    // 2.剔除购物车商品行匹配为空的券；3.剔除门槛差值为0的券
    .filter(c => [1, 5].includes(Number(c.coupon_type_id))
      && [1, 2, 3, 4, 7, 8].includes(Number(c.apply_for))
      && [1, 2].includes(Number(c.coupon_dimension))
      && c.mall_cart_coupon_goods_lists.length
      && getCouponDiff(c) !== '0'
    ) || []
}

/**
 * 从券助手数据中获取可作为筛选标签的券数据来源
 * @param {*} couponFilterLabelData 
 * @param {*} lang 
 * @returns 
 */
export function getCouponLabel(couponFilterLabelData, lang, locals) {
  const couponList = getAvailableCoupon(couponFilterLabelData)
  return couponList
    // 整合券可用信息，多档券的优惠信息显示为最高档优惠
    .map(c => {
      const { id, other_coupon_rule, coupon, end_date, coupon_type_id } = c
      return {
        id,
        coupon,
        coupon_type_id,
        end_date,
        needPrice: getCouponDiff(c),
        discount: getDiscountByLevel(c, other_coupon_rule.length - 1, locals)
      }
    })
    // 同样的券，筛选出距离券过期时间较早的券
    .reduce((acc, current) => {
      const existing = acc.find(item => item.needPrice == current.needPrice && item.discount == current.discount)
      if (!existing || existing.end_date > current.end_date) {
        return acc.filter(item => item !== existing).concat(current)
      }
      return acc
    }, [])
    .sort((a, b) => a.needPrice - b.needPrice) // 按照门槛差值排序
    // 整合成券标签
    .map(item => {
      const { discount, id, coupon_type_id, coupon } = item
      return {
        type: LabelKeyMap.COUPON,
        orderKey: filterOrderKey[LabelKeyMap.COUPON],
        id,
        tip: template(discount, lang.SHEIN_KEY_PWA_30072),
        couponType: coupon_type_id == '1' ? `coupon_money_${coupon}` : `coupon_off_${coupon}`, // 用于埋点
        iconName: filterLabelIcon[LabelKeyMap.COUPON],
      }
    })
}

/**
 * 从csc平台获取券数据
 */
function formatCouponLabelFromCsC(couponLabelFromCsc, lang, locals) {
  const couponList = couponLabelFromCsc?.userReceiveCouponList
  if (!couponList?.length) return []
  return couponList.map(c => {
    const couponInfo = c?.coupon_response_vo
    const discount = c.coupon_response_vo?.discount_type == 1
      ? couponInfo?.rules[couponInfo?.rules.length - 1]?.value_amount_with_symbol
      : (locals?.GB_cssRight ? '%' + couponInfo?.rules[couponInfo?.rules.length - 1]?.value : couponInfo?.rules[couponInfo?.rules.length - 1]?.value + '%')
    return {
      type: LabelKeyMap.COUPON,
      orderKey: filterOrderKey[LabelKeyMap.COUPON],
      iconName: filterLabelIcon[LabelKeyMap.COUPON],
      id: String(couponInfo.coupon_id),
      tip: template(discount, lang.SHEIN_KEY_PWA_30072),
      couponType: couponInfo?.discount_type == 1
        ? `coupon_money_${couponInfo?.coupon_code}`
        : `coupon_off_${couponInfo?.coupon_code}`, // 用于埋点
    }
  })
}

/**
 * 判断当前选中券是否适用于指定商品行
 * @param {*} goodItem 购物车商品行信息
 * @param {*} targetCouponInfo 当前选中作为筛选条件的券
 * @returns 
 */
function goodsMatchCoupon(goodItem, targetCouponInfo) {
  const cartIds = targetCouponInfo?.mall_cart_coupon_goods_lists?.map(i => i?.cart_id) || []
  return cartIds.includes(goodItem.id)
}

/**
 * 计算满足下一档位门槛的差额
 * 下一档位: satisfied_range
 * satisfied_range为0，取couponItem.needPrice?.amount
 * 否则取对应档位need_price?.amount
 * @param {*} couponItem 
 * @param {*} symbol 金额类型，为true带货币信息
 * @returns 
 */
export function getCouponDiff(couponItem, symbol = false) {
  const { satisfied_range = '0', needPrice = {}, other_coupon_rule = [] } = couponItem

  if (satisfied_range == '0') {
    return symbol ? needPrice?.amountWithSymbol : needPrice?.amount
  } else {
    const targetRule = other_coupon_rule[satisfied_range] || {}
    return symbol
      ? targetRule?.need_price?.amountWithSymbol || '0'
      : targetRule?.need_price?.amount || '0'
  }
}

/**
 * 计算指定档位的优惠(折扣或满减)
 * coupon_type_id == '1' 为金额券，否则为折扣券
 * 金额券取指定档位的price?.priceSymbol
 * 折扣券需要拼接%
 */
export function getDiscountByLevel(couponItem, level, locals) {
  const { coupon_type_id = '', other_coupon_rule = [] } = couponItem
  return coupon_type_id == '1'
    ? other_coupon_rule[level]?.price?.priceSymbol
    : (locals?.GB_cssRight ? '%' + (Number(other_coupon_rule[level]?.price?.price || 0) * 100 / 100) : (Number(other_coupon_rule[level]?.price?.price || 0) * 100 / 100) + '%')
}

/* --- 券筛选相关工具函数end --- */
