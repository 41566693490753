import { isEqual } from 'lodash'
import Vue from 'vue'
import { getAddonTips } from 'public/src/pages/cartNew/components/EstimatedDetailDrawer/addPromoTools.js'

const DialogComponentMap = {
  soldOutDialogShow: 'soldOutVue', // 售罄弹窗
  cartCouponHelperShow: 'CartCouponHelper', // 优惠券助手
  cartSharePageShow: 'CartSharePage', // 购物车分享页
}

const cartDialogComponentMap = {
  addOnInfo: 'addForCouponIsLoaded', // 优惠券凑单
  showShareDraw: 'cartShareDrawIsLoaded', // 优惠券凑单
  fullGiftShow: 'cartFullGiftsDrawIsLoaded',
  addBuyShow: 'cartAddBuyDrawIsLoaded',
  buyGiftShow: 'cartBuyGiftDrawIsLoaded',
  clubGiftShow: 'cartClubGiftDrawIsLoaded',
  showCanceledOrderDraw: 'canceledOrderProductListIsLoaded',
  showCartFloorDrawer: 'cartFloorDrawerIsLoaded', // 购物车楼层弹窗
  showPromotionInterceptionDialog: 'cartPromotionInterceptionIsLoaded', // 库存超限
}

const handleChangeIsCheckedByLocal = (state, isChecked, selectList) => {
  if (isChecked === undefined) {
    state.isCheckedByLocal = {}
    return
  }
  state.mallCartList.forEach(mallCart => {
    mallCart.mall_list.forEach(mall => {
      // showOBMStore 为 true 时，key 为 list，否则为 store_list
      const isShowOBMStore = !!mall.list
      const list = isShowOBMStore ? [mall] : (mall.store_list || [])
      list.forEach(item => {
        item.list?.forEach(li => {
          // checkbox 被禁用的商品（赠品/礼品/新人价商品）不设置本地状态
          if (li.isDisabled) return
          if (selectList) {
            if (selectList.includes(li.id)) {
              Vue.set(state.isCheckedByLocal, li.id, isChecked)
            }
          } else {
            Vue.set(state.isCheckedByLocal, li.id, isChecked)
          }
        })
      })
    })
  })
}

const mutations = {
  showLoading(state) {
    if (state.loadingTimer) {
      clearTimeout(state.loadingTimer)
      state.loadingTimer = null
    }
    state.loadingTotal += 1
    state.loadingTimer = setTimeout(() => {
      state.loadingShow = true
    }, 800)
  },
  /**
   * @param {Boolean} force 是否强制隐藏loading
   */
  hideLoading(state, force) {
    if (!force) {
      if (state.loadingTotal > 0) state.loadingTotal -= 1
      if (state.loadingTotal > 0) return
    }
    if (state.loadingTimer) {
      clearTimeout(state.loadingTimer)
      state.loadingTimer = null
    }
    state.loadingTotal = 0
    state.loadingShow = false
  },
  // 修改本地 isCheckedByLocal 的选中状态
  modifyCheckedBeforeLoading(state, payload) {
    Vue.nextTick(() => {
      const { operation_type, cart_id_list } = payload
      switch (operation_type) {
        case 1:
          // 批量选择
          handleChangeIsCheckedByLocal(state, 1, cart_id_list)
          break
        case 2:
          // 批量取消选择
          handleChangeIsCheckedByLocal(state, 0, cart_id_list)
          break
        case 3:
          // 全选
          handleChangeIsCheckedByLocal(state, 1)
          break
        case 4:
          // 取消全选
          handleChangeIsCheckedByLocal(state, 0)
          break
        default:
          break
      }
    })
  },
  // 清除本地 isCheckedByLocal 的选中状态
  handleClearIsCheckedByLocal(state, payload = {}) {
    const { operation_type, cart_id_list } = payload
    const args = [state, undefined]
    if ([1, 2].includes(operation_type)) args.push(cart_id_list)
    handleChangeIsCheckedByLocal(...args)
  },
  resetLocals(state) {
    if (state?.locals) return
    if (typeof window === 'undefined') return
    let { WEB_CLIENT, IS_RW, host, lang, langPath, currency, appLanguage, GB_cssRight, LAZY_IMG, IMG_LINK, PUBLIC_CDN, CART_STOCK_TIPS_SWITCH, SiteUID, currencies, RESOURCE_SDK, IS_SUGGESTED, CART_FILTER_LABEL_COUNT, CART_FILTER_BEHAVIOR_LABELS, EVOLU_SHEIN_ID, CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS,
      SHEIN_STORE_CODES,
    } = gbCommonInfo
    let currencyDecPointSymbol = ''
    const USER_LOCAL_SIZE_COUNTRY = window.localStorage.getItem('last_select_country')
    let currencyObj = currencies?.[lang]?.[currency] || {}
    let { separator_multi_resp_list = [] } = currencyObj || {}
    let existSeparator = separator_multi_resp_list?.filter(item => item?.language_flag == appLanguage)
    if (existSeparator?.length) {
      currencyDecPointSymbol = existSeparator?.[0]?.dec_point_symbol || ''
    } else {
      currencyDecPointSymbol = currencyObj?.dec_point_symbol || ''
    }
    state.locals = { WEB_CLIENT, IS_RW, host, lang, langPath, currency, appLanguage, GB_cssRight, LAZY_IMG, IMG_LINK, PUBLIC_CDN, CART_STOCK_TIPS_SWITCH, SiteUID, currencyDecPointSymbol, RESOURCE_SDK, IS_SUGGESTED, CART_FILTER_LABEL_COUNT, CART_FILTER_BEHAVIOR_LABELS, EVOLU_SHEIN_ID, USER_LOCAL_SIZE_COUNTRY, CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS,
      SHEIN_STORE_CODES,
    }
  },
  toggleDrawer (state, payload) {
    const { type = '', value = false, isUpdateHelperOnly = false } = payload
    // console.log('ToggleDrawer! (type: %o, value: %o)', type, value)

    if (isUpdateHelperOnly) {
      state.isUpdateHelperOnly = isUpdateHelperOnly
      return
    }

    if(value) {
      state.dialogComponent.other = DialogComponentMap['cartCouponHelperShow']
    }

    switch (type) {
      case 'coupon-helper': state.drawerCouponHelper = !!value; break
    }
  },
  handleHash (state, { hash = '' }) {
    if (!hash) return
    state.bodyFixedTop = typeof window != 'undefined' ? $(window).scrollTop()  : 0
    location.hash = '#' + hash
    
  },
  // 清空dialog状态
  resetDialogComponent (state, key) {
    state.dialogComponent[key] = null
  },
  resetPageStatus (state) {
    
    const hashValue = location.hash.replace('#', '')
    if (hashValue) {
      window.history.go(-1)
      /** 
       * 为了兼容老版本chrome
       * 老版本chrome 的 history添加hash后length未+1，会回到购物车前一个地址
       */
      // location.hash = '' // 清除hash
      // window.history.pushState('', document.title, location.pathname) // 去掉#字符
      if(state.bodyFixedTop){
        setTimeout(()=>{
          $(window).scrollTop(state.bodyFixedTop)
          state.bodyFixedTop = 0
        }, 50)
      }
    }
    $('body').removeClass('mshe-fixed')
    
    Object.keys(state.pageStatus).forEach(key => {
      state.pageStatus[key] = false
    })
  },
  // 未登录状态下，加载paypal弹框组件
  changeAsyncPaypalDialogLoadedStatus (state, payload) {
    const { show } = payload
    if(show) {
      state.asyncComponentLoadedStatus.cartEmailConfirmDrawIsLoaded = true
      state.asyncComponentLoadedStatus.cartAccountRegisterDrawIsLoaded = true
      state.asyncComponentLoadedStatus.cartRegisterFailedDrawIsLoaded = true
    }
  },
  /**
   * 
   * @param {*} state
   * @param {*} payload {
   *  promotionId: 活动id,
   *  status: 'fullGiftShow' | 'addBuyShow' | 'buyGiftShow' | 'clubGiftShow',
   *  fromType: 'pick' | 'addItem' ｜ 'addItem-auto'
   *  }
   */
  changeAppendageDrawerStatus (state, payload) {
    const { status, promotionId, fromType = 'pick' } = payload
    if(cartDialogComponentMap[status] && !state.asyncComponentLoadedStatus[cartDialogComponentMap[status]]){
      state.loadingShow = true
      state.asyncComponentLoadedStatus[cartDialogComponentMap[status]] = true
    }
    state.appendageDrawer = {
      promotionId,
      fromType,
      fullGiftShow: status == 'fullGiftShow',
      addBuyShow: status == 'addBuyShow',
      buyGiftShow: status == 'buyGiftShow',
      clubGiftShow: status == 'clubGiftShow'
    }
  },
  resetAppendageDrawerStatus (state) {
    state.appendageDrawer = {
      promotionId: '',
      fromType: '',
      fullGiftShow: false,
      addBuyShow: false,
      buyGiftShow: false
    }
  },
  changePageStatus (state, payload) {
    const { status } = payload

    if(cartDialogComponentMap[status] && !state.asyncComponentLoadedStatus[cartDialogComponentMap[status]]){
      state.loadingShow = true
      state.asyncComponentLoadedStatus[cartDialogComponentMap[status]] = true
    }

    Object.keys(state.pageStatus).forEach(key => {
      state.pageStatus[key] = key == status || state.pageStatus[key]
    })

    if (DialogComponentMap[status]) {
      state.dialogComponent.other = DialogComponentMap[status]
    }
  },
  changeEstimatedPriceDrawerParams(state, payload) {
    if (payload.show && !state.asyncComponentLoadedStatus.cartEstimatedDetailDrawIsLoaded) {
      state.loadingShow = true
      state.asyncComponentLoadedStatus.cartEstimatedDetailDrawIsLoaded = true
    }
    state.estimatedPriceDrawerParams = {
      show: payload.show,
      type: payload.type,
      cartItem: payload.cartItem || state.estimatedPriceDrawerParams.cartItem,
    }
  },
  updateEstimatedAddPromo(state, payload) {
    if(payload.item) {
      if(state.estimatedPriceDrawerParams?.type == 'DISCOUNT') {
        state.estimatedAddonTips = getAddonTips(payload.item, state.integrityPromotionInfo, state.couponInfo, state.language)
      } else {
        state.estimatedAddonTips = getAddonTips(payload.item, state.integrityPromotionInfo, {}, state.language) // ESTIMATED 不需要券数据
      }

    } else {
      state.estimatedAddonTips = []
    }
  },
  updateStateCheckEqual(state, payload) {
    const { key, value } = payload
    if (isEqual(state[key], value)) return
    state[key] = value
  },
  updateState (state, payload) {
    const { key, value } = payload
    // console.log('Update!(%o: %o)', key, value)
    // console.log('%cUpdate%c(%o: %o)', 'color: green; font-weight: bold', '', key, value)
    if(key == 'showDiscountDetails' && typeof window != 'undefined') {
      if(value) {
        $('.j-uptt').css('visibility', 'hidden')
        $('body').css('overflow', 'hidden')
      } else {
        $('.j-uptt').css('visibility', 'visible')
        $('body').css('overflow', '')
      }
    }

    if (cartDialogComponentMap[key] && (value.show || value === true)) {
      if(!state.asyncComponentLoadedStatus[cartDialogComponentMap[key]]){
        state.loadingShow = true
        state.asyncComponentLoadedStatus[cartDialogComponentMap[key]] = true
      }
    }

    if (key === 'cartInfo') {
      return console.log('%Error%c(Please use "updateCartInfo" action instead of "updateState")', 'color: red; font-weight: bold')
    } else if (state[key] !== undefined) {
      state[key] = value
    }
    DialogComponentMap[key] && (state.dialogComponent.other = DialogComponentMap[key])
  },
  updateCartInfo (state, payload = {}) {
    const cartInfo = payload.cartInfo || state.cartInfo || {}
    state.cartInfo = cartInfo
  },
  updateCartInfoState (state, payload = {}) {
    const { key, value } = payload
    state.cartInfo[key] = value
  },
  updateCartAbtInfo (state, payload) {
    const { abtInfo } = payload
    Object.entries(abtInfo).forEach(([key, content]) => {
      if (content.posKey) {
        state.cartAbtInfo[key] = content
      }
    })
  },
  handleBatchActive (state, payload) {
    const { value, checkSoldout } = payload

    if (value === undefined) {
      state.batchActive = !state.batchActive
    } else {
      state.batchActive = value
    }

    if (state.batchActive) {
      if (checkSoldout) {
        state.batchEditItemIds = [].concat(state.soldoutItems.map(item => item.id))
      }
    } else {
      state.batchEditItemIds = []
    }
    state.lastBatchEditItem = {}
  },
  updateBatchEditItemIds (state, { add = [], del = [] }) {
    const { batchEditItemIds } = state
    while(add.length || del.length) {
      if (add.length) {
        const id = add.shift() 
        const index = batchEditItemIds.indexOf(id)
        if (index === -1) {
          state.batchEditItemIds.push(id)
        }
      } else if (del.length) {
        const id = del.shift()
        const index = batchEditItemIds.indexOf(id)
        if (index > -1) {
          state.batchEditItemIds.splice(index, 1)
        }
      }
    }
  },
  updateLastBatchEditItem(state, payload) {
    const { key, value } = payload
    state.lastBatchEditItem[key] = value
  },
  /**
   * 打开通用弹窗
   */
  handleModal (state, payload) {
    const { SHEIN_KEY_PWA_15146 = '', SHEIN_KEY_PWA_15498 = '' } = state.language
    const { show = true, okCallback, cancelCallback, oktext = SHEIN_KEY_PWA_15146, canceltext = SHEIN_KEY_PWA_15498, text = '', okDaId = '', cancelDaId = '' } = payload
    
    const okCb = function () {
      state.modalOptions.show = false
      okCallback && okCallback()
    }

    const cancelCb = function () {
      state.modalOptions.show = false
      cancelCallback && cancelCallback()
    }

    state.modalOptions = {
      show,
      okCallback: okCb,
      cancelCallback: cancelCb,
      oktext,
      canceltext,
      text,
      component: 'commonModalOptions',
      okDaId,
      cancelDaId
    }
  },
  /**
   * 设置页面参数
   */
  setCartSaPageInfoParams (state, { SaPageInfo, cartInfo } = {}) {
    let _SaPageInfo = SaPageInfo || window.SaPageInfo
    let _cartInfo = cartInfo || state.cartInfo || {}
    if (_SaPageInfo?.page_name === 'page_cart') {
      const bagGoodsCount = _cartInfo.cartSumQuantity || 0
      if (typeof _SaPageInfo?.page_param === 'object') {
        Object.assign(
          _SaPageInfo.page_param,
          {
            bag_goods_count: bagGoodsCount
          }
        )
      } else {
        _SaPageInfo.page_param = {}
        _SaPageInfo.page_param.bag_goods_count = bagGoodsCount
        _SaPageInfo.page_param.page_from = '' //TODO: 确认取值范围
      }
      _SaPageInfo.page_param.mall_code = _cartInfo.mall_list?.map(item => item.mall_code).join(',') || ''
    }
  },
  updateCartItemMap(state, payload) {
    const { carts } = payload
    const lowinventoryItemInfo = {
      id: '', // first Oos item id
      imgItemId: '', // second Oos item img url
      itemIds: [], // all Oos item ids
      languageKey: '',
    }
    carts.forEach(item => {
      // oos item process data
      if((item.isShowOnlyXLeftBelt || item.isShowSoldOutBelt) && !state.soldoutIds.includes(item.id)) {
        if(!lowinventoryItemInfo.id) {
          lowinventoryItemInfo.id = item.id
        } else if(lowinventoryItemInfo.id && !lowinventoryItemInfo.imgItemId) {
          lowinventoryItemInfo.imgItemId = item.id
        }
        lowinventoryItemInfo.itemIds.push(item.id)
      }
      if (state.cartItemMap[item.id] === undefined || !isEqual(state.cartItemMap[item.id], item)) {
        Object.assign(item, {
          is_checked_by_local: state.cartItemMap[item.id]?.is_checked_by_local
        })
        Vue.set(state.cartItemMap, item.id, item)
        // state.cartItemMap[item.id] = item
        return
      }
    })
    state.lowinventoryItemInfo = lowinventoryItemInfo
  },
  updateMallCartList(state, payload) {
    const { mallCartList } = payload
    return state.mallCartList = mallCartList
  },
  updateCartList(state, payload) {
    const { cartList } = payload
    state.cartList = cartList
  },
  updateAddressLocal (state, payload) {
    state.isAddressLocalUpdate = payload
  },
  updateBottomActivityConfig (state, payload) {
    state.bottomActivityConfig = {
      ...state.bottomActivityConfig,
      ...payload,
    }
  },
  updateLocals(state, payload) {
    state.locals = Object.assign(state.locals, payload ?? {})
  }
}

export default mutations
