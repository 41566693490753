import { promotionCenterNew } from 'public/src/pages/common/promotion/indexNew'
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import * as api from './api'
import { handlerCartsPromotionGroup, getGroupType, getStoreItemByStoreCode } from 'public/src/pages/cartNew/utils/handler.js'
// import { shippingCenter } from 'public/src/pages/common/shipping/index.js'
import { scrollToCartItem, isValidCartItem, isThirtyLowestPrice } from 'public/src/pages/cartNew/utils'
import { setLocalStorage, isEmpty, template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import blackFridayImg from 'public/src/pages/cartNew/utils/blackFridayImg'
import BehaviorLabel from 'public/src/pages/cartNew/utils/behaviorLabel.js'
import prerenderCheckout from 'public/src/pages/cartNew/utils/prerender'
import { Toast } from '@shein/sui-mobile'
import { getFilterLabelsByCartItem, composeFilterLabels } from 'public/src/pages/cartNew/utils/cartFilterLabels.js'
import { fetchMallShippingInfo, updateRootStateFreeShipping, getTaxTip, getQsTimelinessInfo, getCanceledOrderInfo, getRightsFunsInfo, getCartIndex } from 'public/src/pages/cartNew/utils/fetcher.js'
import { shuffleArr, getCountryId } from 'public/src/pages/cartNew/utils/index.js'
import md5 from 'md5'
import { uniqBy } from 'lodash'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'
import { getXLowestPopoverTips, floorToNearestBase } from 'public/src/pages/cartNew/components/cartCheckoutIncentive/incentiveTools.js'
import { getQueryString } from '@shein/common-function'
import { BFF_CART_API_FETCH } from 'public/src/pages/cartNew/utils/PromiseActuator.js'

daEventCenter.addSubscriber({ modulecode: '1-8-1' })

const actions = {
  evaluateFirstScreenSource({ commit, dispatch }, { firstScreenSource, SaPageInfo, abtInfo }) {
    if(firstScreenSource && Object.prototype.toString.call(firstScreenSource) === '[object Array]'){
      const [mallInfo] = firstScreenSource
      const { isPlatForm = false, siteMallList = [] } = mallInfo
      commit('updateState', { key: 'isPlatForm', value: isPlatForm })
      commit('updateState', { key: 'siteMallList', value: siteMallList })
    }
    commit('updateCartAbtInfo', { abtInfo })
    dispatch('handleSaPageInfo', { SaPageInfo })
    
  },
  async initSsrData({ dispatch, state }) {
    promotionCenterNew.setCacheIntegrityPromotionInfo(state.originIntegrityPromotionInfo, state.promotionLanguage, state.locals?.lang)
    let originCartInfo = state.originCartInfo || {}
    await dispatch('handleMallShippingInfo', {
      cartInfo: originCartInfo,
      originMallShippingInfo: state.originMallShippingInfo,
      isSSR: true
    })
    await dispatch('asyncUpdateCartInfo', { cartInfo: originCartInfo, isSSR: true })
  },
  updateGbSsrData ({ commit, dispatch, state, rootState }, { force = false, cb, cartInfo = null }) {
    if(cartInfo) {
      scrollToCartItem({ id: cartInfo.carts && cartInfo.carts[0]?.id, smooth: true })
      return dispatch('asyncUpdateCartInfo', { cartInfo: cartInfo })
    }
    return new Promise((resolve, reject) => {
      function handleDataReady(cartlists, isUpdateCartInfo) {
        window.GB_cartlists = cartlists

        function doUpdate() {
          if (isUpdateCartInfo) {
            requestAnimationFrame(() => {
              dispatch('asyncUpdateCartInfo', { cartInfo: cartlists }).then(() => {
                resolve(cartlists)
              })
            })
          } else {
            resolve(cartlists)
          }
        }

        if ( JSON.stringify(state.language) == '{}' ) {
          if ( rootState?.cartInfo?.language && Object.keys(rootState?.cartInfo?.language).length ) {
            commit('updateState', { key: 'language', value: rootState?.cartInfo?.language })
            doUpdate()
          } else {
            schttp({
              url: '/api/common/language/get',
              params: {
                page: 'cart'
              }
            }).then(({ language }) => {
              commit('updateState', { key: 'language', value: language })
              doUpdate()
            })
          }
        } else {
          doUpdate()
        }


      }

      if ( rootState && rootState.cartInfo && rootState.cartInfo.info && rootState.cartInfo.info.carts && !force ) {
        dispatch('fetchCarts')
        if ( cb && typeof cb == 'function' ) {
          cb(rootState.cartInfo.info)
        }
        handleDataReady(rootState.cartInfo.info)
      } else {
        schttp({
          url: '/api/cart/getCart/get',
          method: 'POST',
          data: {
            xhr: true
          }
        }).then(res => {
          if ( res && res.cartlists ) {
            if ( cb && typeof cb == 'function' ) {
              cb(res.cartlists)
            }
            commit('updateState', { key: 'language', value: res.cartLanguage })
            handleDataReady({ ...res.cartlists, ...{ nlkt: res.nlkt || 0 } }, true)
          } else {
            reject(new Error('Fetch cart index fail'))
          }
        })
      }
    })
  },

  async fetchModifyCartCheckStatus({ commit, dispatch, state, getters }, payload) {
    commit('showLoading')
    // 在接口返回之前，先修改本地的选中状态
    commit('modifyCheckedBeforeLoading', payload)
    let editCartId = payload.editCartId
    const result = await api.modifyCartCheckStatus(payload)
    if ( result.code == 0 ) {
      let originCarts = JSON.parse(JSON.stringify(getters.cartList))
      await dispatch('asyncUpdateCartInfo', { cartInfo: result.info })
      dispatch('handleFlashSaleOverScene', { editCartId: editCartId || '', carts: result.info?.carts, originCarts })
      state.monitorUserInteraction.stop({ reason: 'update check status' })
    } else {
      if (result.code !== 'ERR_CANCELED') {
        // 接口报错，清除本地的选中状态
        commit('handleClearIsCheckedByLocal', payload)
      }
    }
    commit('hideLoading')
    return Promise.resolve(result.code)
  },
  async asyncUpdateCartInfo ({ commit, dispatch, state, getters }, { cartInfo, isSSR }) {
    let preFetchCartSourceInfo = null
    if(!isSSR){
      if(window.fetchCartSourcePromise) {
        try{
          preFetchCartSourceInfo = await window.fetchCartSourcePromise
        } catch{
          preFetchCartSourceInfo = null
        }
        // eslint-disable-next-line require-atomic-updates
        window.fetchCartSourcePromise = null
      }
    }
    
    // 购物车更新重置（底部全场活动更新需要3种数据都准备好）
    commit('updateBottomActivityConfig', {
      couponIsReady: false,
      promotionIsReady: false,
      freeshipIsReady: false,
    })
    typeof window !== 'undefined' && commit('updateLocals', { USER_LOCAL_SIZE_COUNTRY: window.localStorage.getItem('last_select_country') || '' })

    const promotionPromise =  promotionCenterNew.preHandlePromotion({
      language: state.language,
      goods: cartInfo.carts || [],
      promotion_range: cartInfo.promotion_range,
      isSuggested: getters.isSuggested
    })
    
    if (isSSR) {
      const { integrityPromotionInfo } = await promotionPromise
      commit('updateStateCheckEqual', { key: 'integrityPromotionInfo', value: integrityPromotionInfo })
    } else {
      if(preFetchCartSourceInfo?.length){
        const { integrityPromotionInfo } = await promotionPromise
        commit('updateStateCheckEqual', { key: 'integrityPromotionInfo', value: integrityPromotionInfo })
        commit('updateStateCheckEqual', { key: 'mallShippingInfo', value: preFetchCartSourceInfo[0] })
      } else {
        const shippingPromise = fetchMallShippingInfo({ cartInfo })
        const [{ integrityPromotionInfo }, shippingContentInfo] = await Promise.all([promotionPromise, shippingPromise])
        commit('updateStateCheckEqual', { key: 'mallShippingInfo', value: shippingContentInfo })
        commit('updateStateCheckEqual', { key: 'integrityPromotionInfo', value: integrityPromotionInfo })
      }
    }
    commit('updateBottomActivityConfig', {
      promotionIsReady: true,
      freeshipIsReady: true,
    })

    // change root state
    commit('changeCartInfo', {
      carts: cartInfo.carts,
      sum: cartInfo.cartSumQuantity,
      info: cartInfo,
      isSSR,
      skipPrefetch: true
    }, { root: true })
    // change root state isFreeShipping
    // 服务端渲染时，不需要更新 isFreeShipping
    if (typeof window !== 'undefined') {
      updateRootStateFreeShipping(cartInfo, isFreeShipping => {
        commit('changeFreeShippingState', { isFreeShipping }, { root: true })
        window?._gb_cart_tag_tips_?.update()
      })
    }
    commit('updateCartInfo', { cartInfo })
    dispatch('fetchBffCartIndex')
    dispatch('getCartQsTagInfo') 
    prerenderCheckout.switchTaskState(getters.needFlushPrerenderCache)
    getters.payShowStyle != 'A' && prerenderCheckout.refreshCache(getters.paymentFrontPaymentCode)

    commit('updateState', { key: 'cartSumQuantity', value: cartInfo.cartSumQuantity || 0 })
    commit('updateState', { key: 'mallList', value: cartInfo.mall_list || [] })
    dispatch('handleQueryPaymentFrontShowForCartIndex', { cartInfo, isSSR })
    dispatch('fetchBlackFridayImgInfo', cartInfo)
    dispatch('fetchStoreSelects', cartInfo)
    console.log('Done! change cart info.')
    // 当更新 cartInfo 后，需要清除之前缓存的选中状态
    if (typeof window !== 'undefined') {
      commit('handleClearIsCheckedByLocal')
    }
    
    dispatch('checkSoldOut', { cartInfo: cartInfo })
    dispatch('handleGoodsStatus', { cartInfo: cartInfo })
    dispatch('updatePromotionRuleList', { promotionInfo: state.integrityPromotionInfo })
    
    dispatch('handleCartInducementInfo')
    dispatch('handleChannelSessionParams')
  },
  handleGoodsStatus(store, { cartInfo }) {
    const carts = cartInfo?.carts || []
    const getSaleGoodsStauts = (item) => {
      const noMainSaleAttrs = item.product?.sku_sale_attr && item.product?.sku_sale_attr?.filter(item => !item.is_main && item.attr_value_id) || []
      let oldSingleOrOneSize = (!item.attr || item.attr?.attr_value_en == 'one-size' || item.attr?.attr_value_id === 0 )
      const isNoOtherAttrs = (oldSingleOrOneSize || (item.product.sku_code && !noMainSaleAttrs.length))

      return !item.isSoldOutItem && !item.isCustomGood && !item.isGiftItem && !item.isAddItem && !item.isNewcomerItem && !isNoOtherAttrs ? 'sku_switch_available' : 'sku_switch_unavailable'
    }
    carts.forEach((item) => {
      item.saleGoodsStatus = getSaleGoodsStauts(item)
      item.soldOutGoodsStatus = item.isSoldOutItem && !item.isMallSwitchGoods && !item.isSaleAttrSwitchGoods ? 'goods_status_oos' : item.isMallSwitchGoods ? 'goods_status_mall_switch' : item.isSaleAttrSwitchGoods ? 'goods_status_sku_switch' : ''
      item.goodsStatus = item.isSoldOutItem ? item.soldOutGoodsStatus : item.saleGoodsStatus
    })
  },
  checkSoldOut({ commit, dispatch, state, getters }, { cartInfo = null, outStockCarts = null }) {
    let soldoutItems = []
    // 其他mall可售商品
    const getPrioritySwitchMallList = (item) => {
      // 当前商品可售的MALL
      const saleMallList = item.new_mall_code_list?.filter(mall => mall.mall_code != item.mall_code) ?? []
      // 当前商品有库存的MALL
      const stockMallList = item.stock_mall_list?.filter(mall => mall.mall_code != item.mall_code) ?? []
      // 可销售且有库存的MALL
      const saleStockMallList = stockMallList?.filter(item => saleMallList.includes(item.mall_code)) ?? []
      // 站点基础MALL信息优先级排序
      const siteMallListSorted = state.siteMallList?.sort((a, b) => a.mall_sort - b.mall_sort) ?? []
      // 最高优先级可切MALL
      const prioritySwitchMallList = saleStockMallList?.sort((a, b) => {
        return siteMallListSorted?.indexOf(a.mall_code) - siteMallListSorted?.indexOf(b.mall_code)
      })
      return prioritySwitchMallList?.[0]
    }
    const isMallSwitchGoods = (item) => {
      return !item.isCustomGood && !item.isGiftItem && !item.isAddItem && !!getPrioritySwitchMallList(item)
    }
    // 可售属性切换商品
    // CartItemAttrBtn.vue 内部逻辑迁移过来
    const isSaleAttrSwitchGoods = (item, isPlatform) => {
      if (!isValidCartItem(item, isPlatform)) return false
      const isAddBuyGoodsOrGiftGoods = (item) => item.product.product_promotion_info && item.product.product_promotion_info.some(promo => [1, 2].includes(+promo.promotion_product_type))
      return item.isSheinClubGiftItem && !item.isInvalid ? true : !isAddBuyGoodsOrGiftGoods(item) && !item.isLoseNewcomerItem
    }
    if ( outStockCarts ) {
      soldoutItems = state.soldoutItems
      let outStockCartsId = outStockCarts.map(item => item.id)
      const soldoutCarts = getters.cartList.filter(item => outStockCartsId.indexOf(item.id) > -1)
      soldoutItems = soldoutItems.concat(soldoutCarts)
      commit('updateState', { key: 'outStockCarts', value: soldoutCarts })
    } else if ( cartInfo ) {
      soldoutItems = cartInfo?.carts?.filter(item => {
        return item?.product?.is_on_sale == 0 ||
          item?.realTimeInventory == 0 ||
          (item.status == 1) ||
          (item.status == 2 && [ 0, 2, 3, 4, 18, 20, 22 ].includes(+item.promotion_status)) ||
          item.status == -1 ||
          // 失去【新人专享价】资格
          (item.status == 2 && [ 11, 13, 14, 15, 16 ].includes(+item.promotion_status)) ||
          item.promotion_status === 21
      }) || [] // 购物车内所有告罄商品
    }
    // 失效商品组商品展示顺序: 其他MALL可售>其他颜色和尺码可售>剩余的失效商品
    soldoutItems.forEach(item => {
      item.isMallSwitchGoods = isMallSwitchGoods(item)
      item.isSaleAttrSwitchGoods = isSaleAttrSwitchGoods(item, state.isPlatForm)
      item.prioritySwitchMall = getPrioritySwitchMallList(item)
      if (item.status == 2 && [ 11, 13, 14, 15, 16 ].includes(+item.promotion_status)) {
        // 失去【新人专享价】资格
        item.isLoseNewcomerItem = true
      }
      if (item.promotionTag?.typeId === 4 && [8, 14].includes(item.promotionTag.promotionLogoType)) {
        // 付费会员赠品
        item.isSheinClubGiftItem = true
        item.isInvalid = !getters.isSheinClubMember
      }
      let isGiftOrAddOn = item.product.product_promotion_info?.some(promo => [ 1, 2 ].includes(+promo.promotion_product_type)) ? true : false
      if ( isGiftOrAddOn || (item.status == -1 && !state.isPlatForm) ) { // 附属品以及非当前站点mall商品不支持修改属性，直接展示'找相似'，故优先级最低
        item.soldoutPriority = 0
      } else if ( state.isPlatForm && item.mall_code_list?.length && (item.status == 1 || item.status == -1) ) { // 多mall站点 商品失效，其他mall可售
        item.soldoutPriority = 2
      } else {
        if (isValidCartItem(item, state.isPlatForm)) {
          item.soldoutPriority = 1
        } else {
          item.soldoutPriority = 0
        }
      }
    })
    soldoutItems.sort((a, b) => {
      return b.soldoutPriority - a.soldoutPriority
    })
    const soldoutIds = soldoutItems.map(item => item.id) // 购物车内告罄商品id
    commit('updateStateCheckEqual', { key: 'soldoutIds', value: soldoutIds })
    // 根据 ABT 剔除满足 isMallSwitchGoods 和 isSaleAttrSwitchGoods 的商品
    const filterSoldOutItems = soldoutItems.filter((item) => !((getters.cartMallSwitchGoods && item.isMallSwitchGoods) || (getters.cartSaleAttrSwitchGoods && item.isSaleAttrSwitchGoods)))
    commit('updateState', { key: 'soldoutItems', value: filterSoldOutItems })

    console.log('Done! handle sold out.')

    if(typeof window != 'undefined'){
      let lastCheckStockTime = Date.now()
      let preCheckStockTime = state.lastCheckStockTime
      commit('updateState', { key: 'lastCheckStockTime', value: lastCheckStockTime })
      if(preCheckStockTime && lastCheckStockTime) {
        if(!window.cart_opt_time) window.cart_opt_time = []
        window.cart_opt_time.push((lastCheckStockTime - preCheckStockTime))
      }
      
    }
    if (getters.cartShowStore && !getters.cartStoreInRow) {
      dispatch('handleMallListInfo', cartInfo?.carts)
    } else {
      dispatch('handlerCartListInfo', cartInfo?.carts)
    }
    dispatch('handleFlashSaleOverLimit')
  },
  handlerCartListInfo({ commit, state, getters, dispatch }, cartsList) {

    let carts = cartsList ? cartsList : getters.cartList
    const normalOnSaleCartList = carts.filter(item => !state.soldoutItems.map(item => item.id).includes(item.id)) // 正常在售商品
    let mallCartList = []
    let promotionLabels = []
    let doFilter = normalOnSaleCartList.length > 4
    normalOnSaleCartList.forEach(cartItem => {
      let mallCart = mallCartList.find(obj => obj.mall_code === cartItem.mall_code)
      if ( !mallCart ) {
        mallCart = {
          mall_code: cartItem.mall_code,
          mall_list: []
        }
        mallCartList.push(mallCart)
      }
      let store_code = 'shein'
      let storeCart = mallCart.mall_list.find(mallItem => mallItem.store_code === store_code)
      if ( storeCart ) {
        storeCart.store_list_origin.push(cartItem)
        storeCart.preferred_seller_store = cartItem.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store
      } else {
        let storeItem = {
          store_code: 'shein',
          storeCode: cartItem.store_code,
          store_title: 'shein',
          store_logo: '',
          store_type: cartItem.store_type,
          preferred_seller_store: cartItem.preferred_seller_store,
          store_list: [],
          store_list_origin: []
        }
        storeItem.store_list_origin.push(cartItem)
        mallCart.mall_list.push(storeItem)
      }
      if(doFilter) promotionLabels.push(...getFilterLabelsByCartItem(cartItem, state.language, getters.showQuickShipTag, state.cartQsTagInfo))
    })
    let normal_cart_list = []
    Object.keys(state.integrityPromotionInfo).forEach(promotionId => {
      state.integrityPromotionInfo[promotionId].list = state.integrityPromotionInfo[promotionId].list?.flat() || []
    })
    mallCartList.forEach(mallItem => {
      mallItem.mall_list.forEach(storeItem => {
        storeItem.store_list = handlerCartsPromotionGroup({
          goods: storeItem.store_list_origin,
          integrityPromotionInfo: state.integrityPromotionInfo
        }, {
          cartStoreFreegift: getters.cartStoreFreegift
        })
        storeItem.store_list.forEach(item => {
          item.list = item.list?.flat() || []
          normal_cart_list = normal_cart_list.concat(item.list)
        })
      })
    })

    let cartsData = [].concat(normal_cart_list, state.soldoutItems)
    let cartsId = cartsData.map(item => item.id)
    commit('updateCartItemMap', { carts: cartsData })
    commit('updateStateCheckEqual', { key: 'cartsId', value: cartsId })
    // commit('updateState', { key: 'cartsId', value: cartsId })
    commit('updateMallCartList', { mallCartList })
    commit('updateState', { key: 'promotionLabels', value: uniqBy(promotionLabels, i => i.type) })
    dispatch('handleCartFilterLabels')

    // let cartsData = [].concat(normal_cart_list, state.soldoutItems)
    // commit('updateCartList', { cartList: cartsData })
    // let cartsId = cartsData.map(item => item.id)
    // commit('updateState', { key: 'cartsId', value: cartsId })
    console.log('set cart data')
  },
  handlerObmCartListInfo({ commit, state, getters, dispatch }, cartsList) {

    let carts = cartsList ? cartsList : getters.cartList
    const normalOnSaleCartList = carts.filter(item => !state.soldoutIds.includes(item.id)) // 正常在售商品
    let mallCartList = []
    let promotionLabels = []
    let doFilter = normalOnSaleCartList.length > 4
    normalOnSaleCartList.forEach(cartItem => {
      let mallCart = mallCartList.find(obj => obj.mall_code === cartItem.mall_code)
      if ( !mallCart ) {
        mallCart = {
          mall_code: cartItem.mall_code,
          mall_list: [],
          mall_list_origin: []
        }
        mallCart.mall_list_origin.push(cartItem)
        mallCartList.push(mallCart)
      } else {
        mallCart.mall_list_origin.push(cartItem)
      }
      if(doFilter) promotionLabels.push(...getFilterLabelsByCartItem(cartItem, state.language, getters.showQuickShipTag, state.cartQsTagInfo))
    })

    let normal_cart_list = []
    Object.keys(state.integrityPromotionInfo).forEach(promotionId => {
      state.integrityPromotionInfo[promotionId].list = state.integrityPromotionInfo[promotionId].list?.flat() || []
    })
    mallCartList.forEach(mallItem => {
      mallItem.mall_list = handlerCartsPromotionGroup({
        goods: mallItem.mall_list_origin,
        integrityPromotionInfo: state.integrityPromotionInfo,
        handleStore: true,
        isPlatForm: state.isPlatForm
      }, {
        cartStoreFreegift: getters.cartStoreFreegift
      })
      mallItem.mall_list.forEach(item => {
        item.list = item.list?.flat() || []
        normal_cart_list = normal_cart_list.concat(item.list)
      })
    })

    let cartsData = [].concat(normal_cart_list, state.soldoutItems)
    let cartsId = cartsData.map(item => item.id)
    commit('updateCartItemMap', { carts: cartsData })
    commit('updateStateCheckEqual', { key: 'cartsId', value: cartsId })
    // commit('updateState', { key: 'cartsId', value: cartsId })
    commit('updateMallCartList', { mallCartList })
    commit('updateState', { key: 'promotionLabels', value: uniqBy(promotionLabels, i => i.type) })
    dispatch('handleCartFilterLabels')
    // let cartsData = [].concat(normal_cart_list, state.soldoutItems)
    // commit('updateCartList', { cartList: cartsData })
    // let cartsId = cartsData.map(item => item.id)
    // commit('updateState', { key: 'cartsId', value: cartsId })
    console.log('set cart data')
  },
  handleMallListInfo({ commit, state, getters, dispatch }, cartsList) {
    let carts = cartsList ? cartsList : getters.cartList
    const normalOnSaleCartList = carts.filter(item => !state.soldoutItems.map(item => item.id).includes(item.id)) // 正常在售商品
    let mallCartList = []
    let promotionLabels = []
    let doFilter = normalOnSaleCartList.length > 4
    // TODO: fast patch all store promo gift list init
    Object.values(state.integrityPromotionInfo).forEach(promo => {
      if(promo.all_store_flag == 1 && [2, 28].includes(+promo.type_id)) {
        promo.list = []
      }
    })
    normalOnSaleCartList.forEach(cartItem => {
      let mallCart = mallCartList.find(obj => obj.mall_code === cartItem.mall_code)
      if ( !mallCart ) {
        mallCart = {
          mall_code: cartItem.mall_code,
          mall_list: []
        }
        mallCartList.push(mallCart)
      }
      let { groupType, specialGroup } = getGroupType(cartItem, state.integrityPromotionInfo, {
        cartStoreFreegift : getters.cartStoreFreegift
      })
      let SheinStore = getters.cartShowSheinBrand && [2, 6].includes(+cartItem.store_type)
      
      if(groupType.storeLevel){ // 跨店铺活动聚合
        let item_promotion_info = state.integrityPromotionInfo[cartItem.promotionGroupId]
        let targetPromotionGroup = mallCart.mall_list.find(groupItem => groupItem?.promotion_id == cartItem.promotionGroupId)
        if(targetPromotionGroup){
          /**
           * 礼品置于卡片顶
           * 2: 买赠;
           * 13: 加价购;
           */
          if (cartItem.isAddBuyItem || cartItem.isBuyGiftItem || cartItem.isPartFullGiftItem) {
            targetPromotionGroup.list[0].unshift(cartItem)
          } else {
            // targetPromotionGroup.list.push(cartItem)
            if (cartItem.isMallSwitchGoods) {
              targetPromotionGroup.list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              targetPromotionGroup.list[2].push(cartItem)
            } else {
              targetPromotionGroup.list[0].push(cartItem)
            }
          }
        } else {
          item_promotion_info.list = [
            [],
            [],
            []
          ]
          if (cartItem.isMallSwitchGoods) {
            item_promotion_info.list[1].push(cartItem)
          } else if (cartItem.isSaleAttrSwitchGoods) {
            item_promotion_info.list[2].push(cartItem)
          } else {
            item_promotion_info.list[0].push(cartItem)
          }
          item_promotion_info.group_type = 'promotion'
          mallCart.mall_list.push(item_promotion_info)
        }
      } else if((specialGroup?.fullGiftCard || specialGroup?.addBuyAllCard) && ((cartItem.isSheinClubGiftItem || cartItem.isFullGiftItem) || cartItem.isAddBuyAllItem)){ // 活动4和22 聚合方式不同
        let promotionGroup = specialGroup?.fullGiftCard || specialGroup?.addBuyAllCard
        let storeCart = getStoreItemByStoreCode(mallCart, SheinStore, cartItem, {
          cartStoreFreegift: getters.cartStoreFreegift,
          promotionDetials: state.integrityPromotionInfo
        })

        storeCart.store_list_origin.unshift(cartItem)
        storeCart.preferred_seller_store = cartItem.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store

        let innerGroup = storeCart.store_list.find(innerGroup => innerGroup?.promotion_id == promotionGroup.promotion_id)
        if(innerGroup) {
          if (cartItem.isSheinClubGiftItem) {
            innerGroup.list[0].unshift(cartItem)
          } else {
            innerGroup.list.push(cartItem)
            if (cartItem.isMallSwitchGoods) {
              innerGroup.list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              innerGroup.list[2].push(cartItem)
            } else {
              innerGroup.list[0].push(cartItem)
            }
          }
        } else {
          innerGroup = promotionGroup
          innerGroup.list = [
            [],
            [],
            [],
          ]
          if (cartItem.isMallSwitchGoods) {
            innerGroup.list[1].push(cartItem)
          } else if (cartItem.isSaleAttrSwitchGoods) {
            innerGroup.list[2].push(cartItem)
          } else {
            innerGroup.list[0].push(cartItem)
          }
          storeCart.store_list.unshift(innerGroup)
        }
      } else if(!groupType.innerStore){ // 普通商品按店铺聚合
        let storeCart = getStoreItemByStoreCode(mallCart, SheinStore, cartItem, {
          cartStoreFreegift: getters.cartStoreFreegift,
          promotionDetials: state.integrityPromotionInfo
        })
        storeCart.store_list_origin.push(cartItem)
        storeCart.preferred_seller_store = cartItem.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store
        // 全店活动附属品单独分组, 放置在组顶部
        if((cartItem.isPartFullGiftItem || cartItem.isBuyGiftItem) && getters.cartStoreFreegift) {
          let innerGroup = storeCart.store_list.find(innerGroup => innerGroup.id == 'fullStoreGift')
          if(innerGroup) {
            innerGroup.list.push(cartItem)
          } else {
            storeCart.store_list.unshift({
              id: 'fullStoreGift',
              list: [ cartItem ]
            })
          }
          // 将附属品塞回全店活动详情中, 方便活动分组状态切换
          let promoDetial = (storeCart?.innerUnGroupPromotions || storeCart?.temporaryInnerUnGroupPromotions)?.find(promo => promo.promotion_id == cartItem?.promotionIds?.[0])
          let havePushed = promoDetial?.list?.find(item => item.id == cartItem.id)
          if(promoDetial?.list && !havePushed) {
            promoDetial.list.push(cartItem)
          } else if (typeof promoDetial == 'object' && !havePushed) {
            promoDetial.list = [ cartItem ]
          }

        } else {
          let innerGroup = storeCart.store_list.find(innerGroup => innerGroup.id == 'common')
          if (innerGroup) {
            // innerGroup.list.push(cartItem)
            if (cartItem.isMallSwitchGoods) {
              innerGroup.list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              innerGroup.list[2].push(cartItem)
            } else {
              innerGroup.list[0].push(cartItem)
            }
          } else {
            const list = [
              [],
              [],
              []
            ]
            if (cartItem.isMallSwitchGoods) {
              list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              list[2].push(cartItem)
            } else {
              list[0].push(cartItem)
            }
            storeCart.store_list.push({
              id: 'common',
              list,
            })
          }
        }
      } else if(groupType.innerStore){ // 按店铺活动内聚合
        let storeCart = getStoreItemByStoreCode(mallCart, SheinStore, cartItem, {
          cartStoreFreegift: getters.cartStoreFreegift,
          promotionDetials: state.integrityPromotionInfo
        })
        storeCart.store_list_origin.push(cartItem)
        storeCart.preferred_seller_store = cartItem.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store

        let innerGroup = storeCart.store_list.find(innerGroup => innerGroup?.promotion_id == cartItem.promotionGroupId)
        if(innerGroup) {
          /**
           * 礼品置于卡片顶
           * 2: 买赠;
           * 13: 加价购;
           */
          if (cartItem.isAddBuyItem || cartItem.isBuyGiftItem || cartItem.isPartFullGiftItem) {
            innerGroup.list[0].unshift(cartItem)
          } else {
            // innerGroup.list.push(cartItem)
            if (cartItem.isMallSwitchGoods) {
              innerGroup.list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              innerGroup.list[2].push(cartItem)
            } else {
              innerGroup.list[0].push(cartItem)
            }
          }
        } else {
          let item_promotion_info = state.integrityPromotionInfo[cartItem.promotionGroupId]

          item_promotion_info.list = [
            [],
            [],
            [],
          ]
          if (cartItem.isMallSwitchGoods) {
            item_promotion_info.list[1].push(cartItem)
          } else if (cartItem.isSaleAttrSwitchGoods) {
            item_promotion_info.list[2].push(cartItem)
          } else {
            item_promotion_info.list[0].push(cartItem)
          }
          item_promotion_info.group_type = 'promotion'
          storeCart.store_list.push(item_promotion_info)
        }
      }
      if(doFilter) promotionLabels.push(...getFilterLabelsByCartItem(cartItem, state.language, getters.showQuickShipTag, state.cartQsTagInfo))
    })
    Object.keys(state.integrityPromotionInfo).forEach(promotionId => {
      state.integrityPromotionInfo[promotionId].list = state.integrityPromotionInfo[promotionId].list?.flat() || []
    })
    
    const storeCodesWitoutShein = mallCartList.map(v => v.mall_list?.filter(mall => mall.store_code !== 'shein')?.map(v => v.store_code))?.flat() || []
    let normal_cart_list = []
    mallCartList.forEach(mallItem => {
      mallItem.mall_list.forEach(storeLevelGroup => {
        if(storeLevelGroup.list) normal_cart_list = normal_cart_list.concat(storeLevelGroup.list)
        else {
          storeLevelGroup.store_list.forEach(group => {
            group.list = group.list?.flat() || []
            normal_cart_list = normal_cart_list.concat(group.list)
          })
        }
        // 假设原店铺下的商品都被移动到shein下了，把对应的附属品活动也移过去（目前只有28的活动）
        if (storeLevelGroup.store_code === 'shein' && storeLevelGroup?.temporaryInnerUnGroupPromotions?.length) {
          storeLevelGroup.innerUnGroupPromotions = storeLevelGroup.temporaryInnerUnGroupPromotions.filter(p => !storeCodesWitoutShein.some(storeCode => storeCode == p.store_id))
        }
      })
    })

    let cartsData = [].concat(normal_cart_list, state.soldoutItems)
    let cartsId = cartsData.map(item => item.id)
    commit('updateCartItemMap', { carts: cartsData })
    commit('updateStateCheckEqual', { key: 'cartsId', value: cartsId })
    // commit('updateState', { key: 'cartsId', value: cartsId })
    commit('updateMallCartList', { mallCartList })
    commit('updateState', { key: 'promotionLabels', value: uniqBy(promotionLabels, i => i.type) })
    dispatch('handleCartFilterLabels')
  },
  async fetchCartAbtInfo ({ commit, dispatch }, { posKeys = '', newPosKeys = '', SaPageInfo }) {
    // eslint-disable-next-line @shein-aidc/abt/abt
    const abtInfo = await abtservice.getUserAbtResult({ posKeys, newPosKeys })
    commit('updateCartAbtInfo', { abtInfo })
    dispatch('handleSaPageInfo', { SaPageInfo })
    return abtInfo
  },
  handleSaPageInfo({ state }, { SaPageInfo }) {
    const _SaPageInfo = SaPageInfo || window.SaPageInfo
    if (_SaPageInfo) {
      if (!_SaPageInfo.page_param) _SaPageInfo.page_param = {}
      // eslint-disable-next-line @shein-aidc/abt/abt
      _SaPageInfo.page_param.abtest = abtservice.getUserAbtResultForAnalysis({ abtInfo: state.cartAbtInfo }).sa
    }
  },
  /**
   * 购物车登录
   * @param {String} type 登录类型
   * @param {Function} updateSingelDataCb 登录状态更新前需要单独更新的数据, 或需要执行的方法
   */
  async handleCartLogin({ commit, state, getters, rootState }, {
    type,
    updateSingelDataCb = () => {},
  }) {
    const result = {
      emptyCart: false,
      equalCart: false
    }
    const beforeLoginCartSumQuantity = state.cartInfo.cartSumQuantity

    let promotion_price
    if (Number(state.cartInfo?.total_discount_price?.amount) > 0) {
      promotion_price = state.cartInfo.total_discount_price.amountWithSymbol
    }
    await api.cartLoginPromise({ type, config: {
      retainData: {
        // 空购物车 不免邮
        free_shipping: getters.carts?.length && rootState.cartInfo.isFreeShipping,
        promotion_price
      }
    } })
    try {
      await updateSingelDataCb()
    } catch ( error ) {
      console.error('updateSingelDataCb Error')
    }
    commit('updateState', { key: 'loginStatus', value: true })
    const [res, ] = await api.updateCart().then(r => [r, null], e => [null, e])
    if ( res?.code == 0 ) {
      const afterLoginCartSumQuantity = res.info?.cartSumQuantity
      result.emptyCart = afterLoginCartSumQuantity == 0 || beforeLoginCartSumQuantity == 0
      result.equalCart = afterLoginCartSumQuantity == beforeLoginCartSumQuantity
    }

    if ( (typeof SaPageInfo !== 'undefined') && (typeof SaPageInfo.page_param == 'object') && !result.equalCart ) {
      SaPageInfo.page_param.is_combine = 0
      setLocalStorage({ key: 'is_combine', value: '0', expire: 1000 * 60 })
    }

    return { result, res: res }
  },
  async fetchStoreSelects({ commit }, cartInfo) {
    if ( typeof window == 'undefined' ) return
    let cartList = cartInfo?.carts || []
    let stores = cartList.filter(item => {
      return item.store_code != 1
    }).map(item => item.store_code)
    let storeCodes = [ ...new Set(stores) ]
    if ( !storeCodes.length ) return
    const storeSelectsInfo = await api.matchStoreJumpUrl(storeCodes)
    commit('updateStateCheckEqual', { key: 'storeSelectsInfo', value: storeSelectsInfo })
  },
  // async fetchMallShippingInfo({ commit }, payload) {
  //   const shippingContentInfo = await shippingCenter.getMallShippingContent(payload)
  //   commit('updateState', { key: 'mallShippingInfo', value: shippingContentInfo })

  // },

  async handleMallShippingInfo({ commit, dispatch }, { cartInfo, originMallShippingInfo = null, isSSR }) {
    if (!isSSR) { 
      dispatch('handleChannelSessionParams')
    }

    const shippingContentInfo = await fetchMallShippingInfo({ cartInfo, originMallShippingInfo })
    commit('updateState', { key: 'mallShippingInfo', value: shippingContentInfo })

    // dispatch('fetchMallShippingInfo', { mall: shippingInfoParams, mallShippingInfo: originMallShippingInfo })
  },

  // 获取购物车quickship标签信息
  async getCartQsTagInfo ({ commit, state }) {
    if (typeof window == 'undefined') return
    // 判断是否是数字或者数字字符串
    const isNumberOrString = (value) => value !== '' && (typeof value === 'number' || typeof value === 'string') && !isNaN(Number(value))
    const { CartQuickshipVisual = {} } = state.cartAbtInfo
    const cartQsVisual = CartQuickshipVisual?.param?.cart_quickship_visual
    const qsTimelinessInfo = await getQsTimelinessInfo({
      mallList: state.cartInfo?.carts?.map(item => ({ mall_code: item.mall_code, store_code: item.store_code, item_no: item.id, skc_code: item.product.goods_sn, sku_code: item.product.sku_code, is_checked: item.is_checked }))
    })
    const qsData = {
      // qs标签新样式
      isShowNewStyle: qsTimelinessInfo?.quickShipTextEn !== 'QuickShip' || cartQsVisual && (cartQsVisual === 'quickship' || isNumberOrString(cartQsVisual)) || false,
      // qs标签标题（前半部分内容）
      text: '',
      // qs标签时效信息（后半部分内容）
      qsTimelinessInfo: {
        isLocationTop: false, // 是否在顶部展示（顶部/商品行）
        tip: '',
      },
      qsMallList: qsTimelinessInfo?.qs_mall_list || {},
      last_quickship_tag: qsTimelinessInfo?.qs_mall_list?.last_quickship_tag || '',
      isJp: qsTimelinessInfo?.isJp || false,
    }
    commit('updateState', { key: 'cartQsTagInfo', value: qsData })
  },
  async fetchSiteMallInfo({ commit }) {
    const { isPlatForm = false, siteMallList = [] } = await api.queryMallInfo()
    commit('updateState', { key: 'isPlatForm', value: isPlatForm })
    commit('updateState', { key: 'siteMallList', value: siteMallList })
    return
  },
  async fetchBlackFridayImgInfo({ commit, state, getters, dispatch }, cartInfo) {
    if ( typeof window == 'undefined' ) return
    let goods = cartInfo?.carts?.map(item => {
      return { goods_id: item.product.goods_id, goods_sn: item.product.goods_sn, mall_code: item.mall_code }
    })
    const behaviorShow = state.cartAbtInfo?.UserBehaviorTips?.param?.CartBehaviorShow
    const behaviorLive = state.cartAbtInfo?.UserBehaviorTips?.param?.CartBehaviorLive
    const normalLabels = BehaviorLabel.getLabelIdsByStr(behaviorShow)
    const liveLabels = BehaviorLabel.getLabelIdsByStr(behaviorLive)
    const cart_goods_label = state.cartAbtInfo?.CartGoodsLabel?.param?.cart_goods_label || ''
    // 筛选栏abt配置的行为标签
    const filterLabelArr = getters.filterLabelOrder.split(',')
    const filterLabels = []
    if (filterLabelArr.includes('30day_additem'))  filterLabels.push('3460')
    if (filterLabelArr.includes('30day_sale'))  filterLabels.push('3451')
    if (filterLabelArr.includes('5star'))  filterLabels.push('3726')

    const blackFridayImgGoods = await blackFridayImg.getBlackFridayImg({ 
      goods: goods,
      machineLabel: [...normalLabels, ...liveLabels, ...filterLabels],
      cart_goods_label,
    })

    const cartsItemMapper = cartInfo?.carts?.reduce((acc, item) => {
      acc[item.product.goods_id] = item
      return acc
    }, {})

    const realTimeLabels = []
    Object.values(blackFridayImgGoods).forEach((item) => {
      if (normalLabels.length) {
        item.showBehaviorLabel = BehaviorLabel.getSortedLabels(
          behaviorShow,
          item?.realTimeTspLabels,
          cartsItemMapper[item.goods_id],
          {
            LowestPriceDays: state.cartAbtInfo?.LowestPriceDays?.param?.lowest_price_days == 'new',
          }
        )
      }
      const goods = cartsItemMapper[item.goods_id]
      if (liveLabels.length && goods && !state.soldoutIds.includes(goods?.id)) {
        const labels = BehaviorLabel.getSortedLabels(
          behaviorLive,
          item?.realTimeTspLabels,
          {},
          {
            LowestPriceDays: state.cartAbtInfo?.LowestPriceDays?.param?.lowest_price_days == 'new',
          }
        ).map((v) => ({
          ...v,
          goodsImg: goods?.product?.goods_img,
          goodsId: goods?.product?.goods_id,
        }))
        
        realTimeLabels.push(...labels)
      }
    })
    dispatch('fetchRealTimeLabelMemberInfo', realTimeLabels)
    commit('updateState', { key: 'blackFridayImgGoods', value: blackFridayImgGoods })
  },
  // wiki.pageId=1018551397
  handleFlashSaleOverScene({ state }, { editCartId, carts, originCarts }) {
    let promotionOverLimit = state.promotionOverLimit || {}
    let flashSaleOverLimit = state.flashSaleOverLimit || {}
    let originCartList = originCarts || []
    let lastCartItemIsOver = false // 编辑前是否超限
    let cartItemIsOver = false // 编辑后是否超限
    let flashSaleOverLimitTarget = flashSaleOverLimit[editCartId] || null
    let originCartItem = originCartList.find(item => item.id == editCartId)
    let cartItem = carts.find(item => item.id == editCartId)
    if ( flashSaleOverLimitTarget ) {
      if ( originCartItem && cartItem ) {
        lastCartItemIsOver = originCartItem.is_checked == 1 && (originCartItem.product.product_promotion_info?.some(promo => promo.single_over == 1 || promo.member_over == 1))
        cartItemIsOver = cartItem.is_checked == 1 && (cartItem.product.product_promotion_info?.some(promo => promo.single_over == 1 || promo.member_over == 1))
        if ( (!lastCartItemIsOver && cartItemIsOver) || (!flashSaleOverLimitTarget.alreadyShow && lastCartItemIsOver && cartItemIsOver) ) { // 非超限态到超限态或者超限态到超限态未弹窗过
          if (!promotionOverLimit.has(editCartId)) {
            flashSaleOverLimitTarget.timeFn()
            promotionOverLimit.add(editCartId)
          }
        } else if ( lastCartItemIsOver && !cartItemIsOver ) { // 超限态到非超限态 清状态缓存
          flashSaleOverLimitTarget.alreadyShow = false
          flashSaleOverLimitTarget.showStatus = false
          promotionOverLimit.delete(editCartId)
        }
      }
    }
    let lastCartItemisStockOver = false // 编辑前是否超限
    let cartItemisStockOver = false // 编辑后是否超限
    if (originCartItem && cartItem) {
      lastCartItemisStockOver = originCartItem.is_checked == 1 && originCartItem.product.product_promotion_info?.some((promo) => promo.stock_over == '1')
      cartItemisStockOver = cartItem.is_checked == 1 && cartItem.product.product_promotion_info?.some((promo) => promo.stock_over == '1')
      if (!lastCartItemisStockOver && cartItemisStockOver) {
        // 非超限态到超限态并且未在闪购超限弹过
        if (!flashSaleOverLimitTarget?.alreadyShow) {
          Toast({ content: state.language.SHEIN_KEY_PWA_23038, duration: 3000 })
          promotionOverLimit.add(editCartId)
          if (flashSaleOverLimitTarget) {
            flashSaleOverLimitTarget.alreadyShow = true
          }
        }
      } else if (lastCartItemisStockOver && !cartItemisStockOver) {
        // 超限态到非超限态 清状态缓存
        promotionOverLimit.delete(editCartId)
        if (flashSaleOverLimitTarget) {
          flashSaleOverLimitTarget.alreadyShow = false
        }
      }
    }
  },
  handleFlashSaleOverLimit({ commit, state, getters }) {
    let flashSaleGroupList = getters.flashSaleGroupList || {}
    let flashSaleOverLimit = {}
    let integrityPromotionInfo = state.integrityPromotionInfo || {}
    let flashSaleGroupListCarts = []
    Object.keys(flashSaleGroupList).forEach(groupListKey => {
      if ( integrityPromotionInfo[groupListKey]?.limit_mode == 1 ) {
        flashSaleGroupListCarts = flashSaleGroupListCarts.concat(flashSaleGroupList[groupListKey])
      }
    })
    Object.keys(state.flashSaleOverLimit).forEach(cartId => {
      if ( !(flashSaleGroupListCarts.find(cart => cart.id == cartId)) ) {
        delete state.flashSaleOverLimit[cartId]
      }
    })
    flashSaleGroupListCarts.forEach(item => {
      if ( state.flashSaleOverLimit[item.id] ) {
        flashSaleOverLimit[item.id] = { ...state.flashSaleOverLimit[item.id], isChecked: item.is_checked == 1 ? true : false, overType: item.normalFlashSale?.single_over == 1 ? 'singleOver' : item.normalFlashSale?.member_over == 1 ? 'memberOver' : '' }
      } else {
        flashSaleOverLimit[item.id] = {
          cartItemId: item.id,
          showType: getters.flashSaleOverShowType,
          overType: item.normalFlashSale?.single_over == 1 ? 'singleOver' : item.normalFlashSale?.member_over == 1 ? 'memberOver' : '',
          alreadyShow: false,
          showStatus: false,
          timmer: null,
          isChecked: item.is_checked == 1 ? true : false,
          timeFn: function () {
            this.alreadyShow = true
            // this.showStatus = true
            daEventCenter.triggerNotice({ daId: this.overType == 'singleOver' ? '1-8-7-57' : '1-8-7-58' })
            if ( this.overType == 'memberOver' || (this.overType == 'singleOver' && this.showType == 'toast') ) {
              Toast({ content: state.language.SHEIN_KEY_PWA_23038, duration: 3000 })
              return
            }
            // if ( this.timmer ) window.clearTimeout(this.timmer)
            // this.timmer = setTimeout(() => {
            //   this.showStatus = false
            //   this.timmer = null

            //   if(state.flashSaleOverLimit[this.cartItemId]) {
            //     state.flashSaleOverLimit[this.cartItemId].showStatus = false
            //     state.flashSaleOverLimit[this.cartItemId].timmer = null
            //   }
            // }, 5000)
          }
        }
      }
    })
    commit('updateStateCheckEqual', { key: 'flashSaleOverLimit', value: flashSaleOverLimit })
  },
  async fetchCarts({ dispatch }, payload) {
    const res = await api.updateCart(payload)
    if(res.code == 0){
      await dispatch('asyncUpdateCartInfo', { cartInfo: res.info })
    }
  },
  async fetchPromotionInfo({ rootState }){
    let needRequestIds = []
    let carts = rootState.cartInfo?.info?.carts || []
    carts.forEach(item => {
      let promotionIds = item.product.product_promotion_info?.map(promotion => promotion.promotion_id) || []
      needRequestIds = needRequestIds.concat(promotionIds)
    })
    let needRequestIdsSet = new Set(needRequestIds)
    needRequestIds = [...needRequestIdsSet]
    if (!needRequestIds.length) return Promise.resolve()
    return await promotionCenterNew.getIntegrityPromotionInfo({ ids: needRequestIds })
  },
  async fetchIncludeTaxInfo({ commit }, payload) {
    const data = await getTaxTip(payload)
    if(data.code == 0) {
      commit('updateState', {
        key: 'taxInclusiveTip',
        value: data?.text || '',
      })
    }
  },

  // 获取附属品活动档位相关的附属商品
  async updatePromotionRuleList({ }, { promotionInfo }) {
    if ( typeof window == 'undefined' ) return
    const promotionList = Object.values(promotionInfo)
    let addBuyAct = promotionList.filter(item => [22, 13].includes(+item.type_id)) || []
    let addBuyActReq = []
 
    addBuyAct.forEach(promotion =>{
      let rulesLength = promotion.rules?.length || 0
      for (let i = 0; i < rulesLength; i++) {
        let reqData = { promotion_id: promotion.promotion_id, range: i + 1, rulesLength }
        addBuyActReq.push(
          schttp({
            url: '/api/cart/postGetAddBuyProductList/get',
            method: 'POST',
            data: reqData
          }).then((res) => {
            if (res.code == 0 && res.info) {
              promotion.rules[i].list = res.info.list || []
              promotion.rules[i].total = res.info.total || 0
            }
          }).catch(() => null)
        )
      }     
    })

    if (!addBuyActReq.length) return
    await Promise.all(addBuyActReq)
  },

  // 请求实时标签的用户信息
  async fetchRealTimeLabelMemberInfo({ commit }, payload) {
    if (!payload || !payload.length) return
    // const memberIds = Array.from(new Set(payload?.map((v) => v?.showLabel?.memberId)))
    // const res = await getMembersInfo(memberIds)
    // if (res.code != 0) return

    const labels = payload.map((v) => {
      let md5Str
      try {
        md5Str = md5(v?.showLabel?.memberId)
      } catch (err) {
        md5Str = 's***e'
      }
      return {
        ...v,
        nickName: `${md5Str[0]}***${md5Str[md5Str.length - 1]}`,
      }
    })

    commit('updateState', {
      key: 'realtimeBehaviorLabels',
      value: shuffleArr(labels),
    })
  },
  // 处理展示分享引导状态
  handleShowGuideState({ commit }, isShow) {
    commit('updateState', { key: 'hasShowShareGuide', value: isShow })
  },
  // 购物车查询支付分期前置文案信息
  async handleQueryPaymentFrontShowForCartIndex({ commit, state, getters }, { cartInfo, isSSR = false } = {}) {
    if (typeof window == 'undefined') return
    const exposeData = function(type) {
      daEventCenter.triggerNotice({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'if_pay_methods',
          type,
        },
      })
    }
    if (!state.locals?.IS_RW && getters.payShowStyle === 'off') exposeData(0)
    if(!(!state.locals?.IS_RW && state.loginStatus && cartInfo.carts?.length && getters.payShowStyle != 'off')) return 
    if(isSSR) {
      return getters.payShowStyle == 'A' && prerenderCheckout.refreshCache(getters.paymentFrontPaymentCode)
    }
    const paymentFrontShowForCartIndexInfo = await api.queryPaymentFrontShowForCartIndex({ amount: getters.isSuggested ?  cartInfo?.total_price_before_coupon?.amount : cartInfo?.totalPrice?.amount })
    commit('updateStateCheckEqual', { key: 'paymentFrontShowForCartIndexInfo', value: paymentFrontShowForCartIndexInfo?.info || [] })
    getters.payShowStyle == 'A' && prerenderCheckout.refreshCache(getters.paymentFrontPaymentCode)

    exposeData((getters.payShowStyleA || getters.payShowStyleB) ? 1 : 0)
  },

  // 更新筛选栏标签
  handleCartFilterLabels({ commit, state, getters }) {
    const soldoutGoodsIds = state.soldoutItems.map(s => s?.product?.goods_id)
    const cartGoodsIds = getters.carts.map(s => s?.product?.goods_id)
    const atomicProducts = {}
    Object.keys(state.blackFridayImgGoods).forEach((goodsId) => {
      if (cartGoodsIds.includes(+goodsId) && !soldoutGoodsIds.includes(+goodsId)) {
        atomicProducts[goodsId] = state.blackFridayImgGoods[goodsId]
      }
    })

    // 如果csc券接口保存，兜底走券助手接口
    if (state.couponLabelFromCsc === null) {
      commit('updateState', { key: 'cscCouponIsError', value: true })
    }

    commit('updateState', {
      key: 'goodsFilterLabels',
      value: composeFilterLabels(
        state.promotionLabels,
        state.couponFilterLabelData,
        getters.filterLabelOrder,
        getters.couponSelectType,
        getters.filterSelectLogo,
        state.language,
        atomicProducts,
        state.locals,
        getters.couponFilterIsCsc,
        state.couponLabelFromCsc,
      ),
    })
  },

  // 聚合购物车利诱点
  handleCartInducementInfo({ commit, state, getters }) {
    const abtArr = state.cartAbtInfo?.IncentiveInform?.param?.IncentiveInformPop?.split(',') || []
    if (!abtArr.length || state.inducementInfoList?.length) return // 没配置abt or 已经有数据不更新了
    const normalCarts = getters.carts.filter(item => !state.soldoutIds.includes(item.id))
    const arr = []
    const { coupon_duration } = gbCommonInfo.CART_INDUCEMENT_LIMIT || {}
    const limitDuration = coupon_duration || (12 * 60 * 60 * 1000) // apollo配置毫秒, 默认12h

    // 根据abt先后顺序处理数据
    for (let i = 0; i < abtArr.length; i++) {
      // 即将售罄利诱
      if (abtArr[i] === 'oos') {
        const ossProducts = normalCarts?.filter(v => v.isIncentiveAlmostOosItem)
        if (ossProducts?.length) {
          let tips = ''
          if (ossProducts.length == 1) {
            tips = ossProducts[0].isShowOnlyXLeftBelt
              ? template(ossProducts[0].product?.real_stock, state.language['SHEIN_KEY_PWA_31542'])
              : `${state.language['SHEIN_KEY_PWA_32705']}<b style="color: #FA6338">${state.language['SHEIN_KEY_PWA_32706']}</b>`
          } else {
            tips = template(ossProducts.length, state.language.SHEIN_KEY_PWA_31501)
          }
          arr.push({
            type: 'oos',
            products: ossProducts.map(v => v.id),
            bubbleTips: tips,
          })
        }
        continue
      }
      // 加车后降价
      if (abtArr[i] === 'mark_down') {
        const markDownProducts = normalCarts?.filter(v => v.reduce_price?.reduceAmount?.amount > 0)
        if (markDownProducts?.length) {
          const maxReduce = markDownProducts.reduce((acc, cur) => {
            return cur.reduce_price?.reduceAmount?.amount < acc.reduce_price?.reduceAmount?.amount ? acc : cur
          })
          const tips = template(maxReduce.reduce_price?.reduceAmount?.amount, state.language[markDownProducts.length == 1 ? 'SHEIN_KEY_PWA_31544' : 'SHEIN_KEY_PWA_31545'])
          arr.push({
            type: 'mark_down',
            products: markDownProducts.map(v => v.id),
            bubbleTips: tips,
          })
        }
        continue
      }
      // 闪购
      if (abtArr[i] === 'flash_sale') {
        const sortPromotions = Object.values(state.integrityPromotionInfo)
          ?.filter((v) => v.type_id == 11 || (v.type_id == 10 && v.flash_type == '1'))
          ?.filter((v) => v.end_time * 1000 > Date.now() && v.end_time * 1000 - Date.now() < limitDuration)
          ?.sort((a, b) => a.end_time - b.end_time)
        if (sortPromotions?.length) {
          const flashProducts = normalCarts?.filter((item) => !!item.product?.product_promotion_info?.some(p => p.promotion_id == sortPromotions[0].promotion_id))
          const tips = flashProducts.length == 1 ? state.language.SHEIN_KEY_PWA_31548 : template(flashProducts.length, state.language.SHEIN_KEY_PWA_31549)
          arr.push({
            type: 'flash_sale', 
            promotionInfo: sortPromotions[0],
            products: flashProducts.map(v => v.id),
            bubbleTips: tips,
            countDown: sortPromotions[0]?.end_time,
          })
        }   
        continue
      }
      // 到期优惠券
      if (abtArr[i] === 'coupon') {
        const sortCoupons = state.couponFilterLabelData?.usableCouponList
          ?.filter((v) => v.end_date * 1000 > Date.now() && v.end_date * 1000 - Date.now() < limitDuration)
          ?.sort((a, b) => a.end_date - b.end_date)
        if (sortCoupons?.length) {
          arr.push({
            type: 'coupon',
            couponInfo: sortCoupons[0],
            bubbleTips: state.language.SHEIN_KEY_PWA_31550,
            countDown: sortCoupons[0]?.end_date,
          })
        } 
        continue
      }
      // 过去30天最低价：3864 | 过去30天最高价：4015 | 是否调价: 4018
      if (abtArr[i] === 'lowest_price_30days') {
        const thirtyLowestGoodIds = Object.values(state.blackFridayImgGoods)
          ?.filter(v => {
            const tsp = v?.realTimeTspLabels || {}
            if (isEmpty(tsp['3864']) || isEmpty(tsp['4015']) || isEmpty(tsp['4018'])) return false
            const cartItem = normalCarts.find(cItem => cItem.product?.goods_id === v.goods_id)
            if (!cartItem) return false
            const result = isThirtyLowestPrice(tsp, cartItem)
            const LowestPriceDays = state.cartAbtInfo?.LowestPriceDays?.param?.lowest_price_days == 'new'
            return LowestPriceDays ? (result[30] || result[60] || result[90]) : result[30]
          }).map((v) => v.goods_id)
        
        const thirtyLowestProducts = normalCarts.filter(v => thirtyLowestGoodIds.includes(v.product?.goods_id))
        if (thirtyLowestProducts?.length) {
          if(state.cartAbtInfo?.LowestPriceDays?.param?.lowest_price_days == 'new') {
            const XLowestPopoverTips = getXLowestPopoverTips(thirtyLowestProducts, state.blackFridayImgGoods, state.language)
            arr.push({
              type: 'lowest_price_30days',
              products: thirtyLowestProducts.map(v => v.id),
              bubbleTips: XLowestPopoverTips?.bubbleTips,
              groupTitleText: XLowestPopoverTips?.groupTitle,
              actualPointLowest: XLowestPopoverTips?.actualPointLowest,
            })
          } else {
            arr.push({
              type: 'lowest_price_30days',
              products: thirtyLowestProducts.map(v => v.id),
              bubbleTips: state.language[thirtyLowestProducts.length == 1 ? 'SHEIN_KEY_PWA_31546' : 'SHEIN_KEY_PWA_31547'],
            })
          }
        }
        continue
      }
      // const THRESHOLD = 10000
      if (abtArr[i] === '5star') {
        const cartIds = []
        let Stars = 0 // cache the stars for only one cartId
        for(let key in state.blackFridayImgGoods) {
          const value = state.blackFridayImgGoods[key]?.realTimeTspLabels?.['3726']
          if(value >= 300) {
            let cartItem = normalCarts.find(i => i.product?.goods_id == key)
            if(cartItem) {
              cartIds.push(cartItem.id)
              Stars = floorToNearestBase(value, 100)
            }
          }
        }
        if(cartIds.length) {
          let bubbleTips = cartIds.length == 1 ? template(Stars > 0 ? `${Stars}+` : '', state.language.SHEIN_KEY_PWA_33269) : state.language.SHEIN_KEY_PWA_33270
          arr.push({
            type: '5star',
            products: cartIds,
            bubbleTips,
            groupTitleText: template(cartIds.length, state.language.SHEIN_KEY_PWA_33273),
          })
        }
      }
      if (abtArr[i] === '30day_sale') {
        const cartIds = []
        let Sales = 0
        for(let key in state.blackFridayImgGoods) {
          const value = state.blackFridayImgGoods[key]?.realTimeTspLabels?.['3451'] || 0
          if(value >= 100) {
            let cartItem = normalCarts.find(i => i.product?.goods_id == key)
            if(cartItem) {
              cartIds.push(cartItem.id)
              Sales = floorToNearestBase(value, 100)
            }
          }
        }
        if(cartIds.length) {
          let bubbleTips = cartIds.length == 1 ? template(Sales > 0 ? `${Sales}+` : '', state.language.SHEIN_KEY_PWA_33267) : state.language.SHEIN_KEY_PWA_33268
          arr.push({
            type: '30day_sale',
            products: cartIds,
            bubbleTips,
            groupTitleText: template(cartIds.length, state.language.SHEIN_KEY_PWA_33272),
          })
        }
      }
      if (abtArr[i] === '30day_additem') {
        const cartIds = []
        let Adds = 0
        for(let key in state.blackFridayImgGoods) {
          const value = state.blackFridayImgGoods[key]?.realTimeTspLabels?.['3460']
          if(value >= 400) {
            let cartItem = normalCarts.find(i => i.product?.goods_id == key)
            if(cartItem) {
              cartIds.push(cartItem.id)
              Adds = floorToNearestBase(value, 100)
            }
          }
        }
        if(cartIds.length) {
          let bubbleTips = cartIds.length == 1 ? template(Adds > 0 ? `${Adds}+` : '', state.language.SHEIN_KEY_PWA_33265) : state.language.SHEIN_KEY_PWA_33266
          arr.push({
            type: '30day_additem',
            products: cartIds,
            bubbleTips,
            groupTitleText: template(cartIds.length, state.language.SHEIN_KEY_PWA_33271),
          })
        }
      }
    }
  
    commit('updateState', {
      key: 'inducementInfoList',
      value: arr,
    })
    // 初始化利诱点类型顺序
    commit('updateState', {
      key: 'inducementSortTypes',
      value: arr.map((v) => v.type),
    })
  },
  // 更新利诱点类型顺序，每曝光一个，把最前面的移动到最后
  updateInducementSortTypes({ commit, state }) {
    if (!state.inducementSortTypes.length) return
    const tempTypes = state.inducementSortTypes
    const firstItem = tempTypes.shift()
    tempTypes.push(firstItem)
    
    commit('updateState', {
      key: 'inducementSortTypes',
      value: tempTypes,
    })
  },
  // 购物车利诱点排序
  sortCartInducementInfo({ commit, state }) {
    if (!state.inducementInfoList.length) return
    const sortedList = []
    state.inducementSortTypes.forEach(type => {
      const item = state.inducementInfoList.find(v => v.type === type)
      if (item) {
        sortedList.push(item)
      }
    })

    commit('updateState', {
      key: 'inducementSortList',
      value: sortedList,
    })
  },
  async fetchCanceledOrderInfo({ commit, state }, payload) {
    // TODO: mock data
    // const data = JSON.parse(localStorage.getItem('mockCancelOrder'))
    // commit('updateState', {
    //   key: 'canceledOrderInfo',
    //   value: data
    // })
    let showedOrders = JSON.parse(sessionStorage.getItem('showedOrders'))
    showedOrders = Array.isArray(showedOrders) ? showedOrders : []
    if(payload?.excludeCurrent) {
      showedOrders = showedOrders.filter(i => i != state.canceledOrderInfo?.billno)
    }
    const canceledOrderInfo = await getCanceledOrderInfo(showedOrders)
    if(canceledOrderInfo?.billno && canceledOrderInfo?.type == '1') {
      commit('updateState', {
        key: 'canceledOrderInfo',
        value: canceledOrderInfo
      })
    }
  },
  async getUseBffApi({ commit }, keys) {
    const value = await isSwitchBffApiVersion(keys)
    commit('updateState', { key: 'useBffApi', value })
  },
  async getCountryList({ commit, state }) {
    if (typeof window == 'undefined') return
    let paypalCartSwitchStatus = state.cartAbtInfo?.paypalCartSwitch?.param?.paypalCartSwitchStatus || 0
    if (paypalCartSwitchStatus == 0) return 
    if(state.countryIdMap.size) return
    const countryList = await api.getUniversalCountryList()
    commit('updateState', { key: 'countryIdMap', value: countryList })
    return
  },
  async handleChannelSessionParams({ commit, state, getters, dispatch }) {
    if (typeof window == 'undefined') return
    if(!getters.showPaypalBtn) return
    await dispatch('getCountryList')
    let countryId = getCountryId() || state.country_id
    let hashMap = {}
    let businessModelList = []
    getters.carts.filter(item => item.is_checked == 1).forEach(cartItem => {
      const hash = `${cartItem.business_model}:${cartItem.company_id || ''}`
      if (!hashMap[hash]) {
        hashMap[hash] = true
        businessModelList.push({
          businessModel: cartItem.business_model,
          companyId: cartItem.company_id
        })
      }
    })
    commit('updateState', { key: 'channelSessionParams', value: {
      shipCountry: state.countryIdMap.get(countryId) || '',
      orderCurrency: gbCommonInfo.currency,
      payMethodList: ['PayPal-GApaypal'],
      orderDetails: businessModelList
    }
    })
  },
  async fetchRightsFunsInfoInClient({commit, state}) {
    if(typeof window == 'undefined') return
    const res = await getRightsFunsInfo({
      skc: state.cartInfo?.carts?.map(i => i?.product?.goods_sn)?.filter(i => i)
    })
    commit('updateState', { key: 'isNewUserOfTsp', value: res?.isNewUserOfTsp || false })
    commit('updateState', { key: 'newUserType', value: res?.newUserType || null })
    commit('updateState', { key: 'isFreeReturn', value: res?.isFreeReturn || false })
  },
  clearIncentivePopover({ commit, state }) {
    commit('updateState', { key: 'showIncentivePopover', value: false })
    if (state.incentivePopoverKeepTimer) {
      clearTimeout(state.incentivePopoverKeepTimer)
      commit('updateState', { key: 'incentivePopoverKeepTimer', value: null })
    }
  },
  async fetchBffCartIndex({ commit, state }) {
    if ( typeof window == 'undefined' ) return
    try {
      if (state.showPromotionInterceptionDialog) {
        commit('showLoading')
      }
      BFF_CART_API_FETCH.init()
      const from = getQueryString({ key: 'from' })
      const reqParams = {
        auto_use_coupon: state.cartAbtInfo?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? '1' : '0',
        mixSelectedCartIds: state.mixSelectedCartIds,
      }
      if (from === 'cart_floor') {
        reqParams.showCartLureNum = getQueryString({ key: 'count' })
        reqParams.showCartLureOrder = getQueryString({ key: 'type' })
      }
      const res = await getCartIndex(reqParams)
      commit('updateState', { key: 'promotionCouponMix', value: res?.info?.mallCartInfo?.promotionCouponMix || null })
      commit('updateState', { key: 'popupCartLure', value: res?.info?.mallCartInfo?.cartLureInfo?.popupCartLure || {} })
      commit('updateState', { key: 'mallCartInfo', value: res?.info?.mallCartInfo || null })
      commit('updateState', { key: 'interceptResultInfo', value: res?.info?.interceptResultInfo || null })
    } finally {
      BFF_CART_API_FETCH.resolve()
      commit('hideLoading')
    }
  }
}

export default actions
