const state = {
  mallInfo: {},
  prerenderSsr: false,
  isSSR: false,
  locals: null,
  checkoutMask: false,
  showDiscountDetails: false,
  loginStatus: false,
  enterCartTimes: 0,
  modalOptions: {
    show: false,
    okCallback: () => {
      this.show = false
    },
    cancelCallback: () => {
      this.show = false
    },
    oktext: '',
    canceltext: '',
    text: '',
    component: null,
    okDaId: '',
    cancelDaId: ''
  },
  cartInfo: {},
  // cartList: [],
  originCartInfo: {},
  couponInfo: {
    usableCouponList: [],
    disabledCouponList: []
  },
  
  cartAbtInfo: {
    PayinCart: {},
    OpenCartCouponHelpernew: {},
    CartCouponHelpernew: {},
    TradeQuickship: {},
    Discountlistshowlimitexceededtip: {},
    OrderPageCouponTest: {},
    UnderPrice: {},
    LowpriceUser: {},
    Cartcouponadd: {},
    recSwitch: {},
    addJson: {},
    addnewhot: {},
    addnewhotJson: {},
    EstimatedDetailSwitch: {},
    CouponAddToCartToast: {},
    CartNotCheckedEstimate: {},
    CartrowEstimatedCountdown: {},
    DiscountInfoSwitch: {},
    cartSelect: {},
    CartAddress: {},
    EmptyCartUnpaidOrder: {},
    CartShippingTopSwitch: {},
    evoluSHEINShow: {},
    CancelledorderTip: {},
    cartDiscountStyle: {},
    storeiconchange: {},
    Cartrefund: {},
    cartPromotions: {},
    LowestPriceDays: {},
    paypalCartSwitch: {},
    CartaddGoodsdetailSwitch: {},
    cartRightsFuns: {},
    CartNewCouponlist: {},
    cartPriceStyle: {},
    cartRecommendGuide: {},
    PaymentSafetyExperiment: {},
    PaymentSecurity: {},
    NewCartrowVision: {},
    CartrowWishlistIcon: {},
    cartShowStoreCode: {},
    cartShowBrand: {},
    cartStoreFreeGift: {},
    outofstocktips: {},
  }, // 购物车Abt
  soldoutItems: [], // 所有告罄商品
  soldoutIds: [], // 所有告罄商品id
  // rangeList: {},
  freeShipping: {
    tip: ''
  },
  pageStatus: {
    cartSharePageShow: false // 购物车分享页
  },
  appendageDrawer: {
    promotionId: '', // 附属品选择弹窗的促销id
    fromType: '', // addItem:凑单页手动唤起附属品弹窗，addItem-auto:凑单页自动唤起附属品弹窗,pick:直接唤起附属品选择弹窗(默认)
    fullGiftShow: false, // 新满赠
    addBuyShow: false, // 新加价购
    buyGiftShow: false, // 新买赠
    clubGiftShow: false, // 付费会员免费礼物权益
  },
  estimatedPriceDrawerParams: {
    show: false,
    cartItem: null,
    type: 'ESTIMATED',
  },
  addOnInfo: {
    show: false,
    config: {},

    // 券凑单数据
    coupon: '',
    secondaryCoupon: [],

    // 活动凑单数据
    promotionId: '',
  },
  dialogComponent: {
    other: null
  }, // 按需加载售光弹窗组件
  // 促销相关
  isPlatForm: false, // 是否平台化多mall
  integrityPromotionInfo: {},
  cartEditItem: {},	// 正在编辑的商品
  batchEditItemIds: [],
  lastBatchEditItem: {},
  batchActive: false,

  soldOutDialogShow: false,
  drawerCouponHelper: false,
  drawerGetCoupon: false, // 打开获取优惠券抽屉
  getCouponMallInfo: {}, // 获取优惠券需要的mall信息
  isUpdateHelperOnly: false,

  language: {},

  // 业务or用户体验要求的提出公共参数
  showM2WPopover: false,
  showBatchEditPopover: false,
  originIntegrityPromotionInfo: {},
  promotionLanguage: {},
  cartNoticeMessage: '',
  loadingTimer: null,
  loadingTotal: 0,
  loadingShow: false,
  isCheckedByLocal: {},
  firstFreeShipping: {
    isFreeShipping: 0,
    leftTime: 0,
    firstFreeShippingTip: '' // 首单免邮提示
  },
  cartCouponsInfo: null,
  cartBannerSsrData: null,
  cartAppCccBannerSsrData: null,
  shippingFreeInfo: {}, //ssr免邮信息
  shippingInfo: {}, //免邮信息
  //以下为新车新增字段
  platformShippingInfo: { //平台级免邮信息
    platform: {}, // 平台级免邮
    self: {}, //自营级免邮
    shop: {} //店铺级免邮
  },
  platformFirstShippingInfo: { // 平台化首单免邮信息
    platform: {}, // 平台级首单免邮
    self: {}, //自营级首单免邮（421版本暂不解析）
    shop: {} //店铺级首单免邮421版本暂不解析）
  },
  storeSelectsInfo: {}, // 店铺选品信息，拼接店铺链接用
  selfCartList: [ { store_code: 'shein', store_list: [] } ], // 自营商品
  localCartList: [], // 第三方店铺商品
  cartsId: [],
  bigLoading: false,
  isNewCart: false,
  openedDeleteItemId: '',
  saveItem: {
    show: false,
    img: ''
  },
  isAddressLocalUpdate: false,
  mallCartList: [],
  mallList: [], // 购物车接口返回的mall列表
  siteMallList: [], //站点所有生效mall列表
  mallShippingInfo: {},
  originMallShippingInfo: {},
  outStockCarts: [],
  bodyFixedTop: 0, // 定位
  blackFridayImgGoods: {},
  showAddressSelectedHeight: false,
  flashSaleOverLimit: {}, //闪购超限支持原价购买提示类型{cartId:{showType:toast,overType:singleOver/memberOver,alreadyShow:true/false,showStatus:true/false,timefn}}
  promotionOverLimit: new Set(), //活动库存超限
  isReflashGetCouponFetch: false, // 是否重新请求领券入口接口
  //wiki.pageId=1075721101
  showRecommendDrawer: false,
  showEmailConfirmDialog: false,
  emailConfirmDialogInfo: {},
  showAccountRegisterDialog: false,
  accountRegisterResult: 0, // 0:注册中 1:注册成功
  showRegisterFailedDialog: false,
  recommendDrawerProduct: { products: [], total: 0, page: 1 }, // 购物车行推荐首页商品数据
  currentShowRecommendEnterCartId: '',
  currentShowRecommendEnterText: '', // 入口文案
  recommendEnterShowFrequency: { recordCartIdList: [], totalCount: 0, recordTime: '' }, // 推荐入口展示频率
  recommendEnterShowTotalCount: 0, // 每个自然日，整个购物车最多展示N次推荐入口 阿波罗配置
  negativeInformationMallCode: '', // 负向信息聚合弹窗mall_code
  showLowPriceUser: false,
  showLowerPriceDiscountDetails: false,
  shippingInfoFinishedTime: null,
  cartItemMap: {},
  lastCheckStockTime: 0,
  cartLeave: false,
  /* --- 购物车筛栏start --- */
  goodsFilterLabels: [],     // 商品筛选标签（活动筛选、券筛选）
  filterType: 'none',        // 当前选中的筛选标签类型, 默认值为none, 不可为空字符串。券筛选时值为券id，可通过!isNaN(filterType)与活动筛选区分
  couponFilterLabelData: {}, // 券筛选标签的来源数据(券助手接口)，但进入购物车或手动刷新才更新数据，所以需要与couponInfo解耦单独维护
  cscCouponIsError: false,   // csc平台券接口是否报错，报错时走券助手接口
  couponLabelFromCsc: {},    // 券筛选标签的来源数据(csc接口)，通过abt参数coupon_recommend来控制用couponFilterLabelData还是couponLabelFromScs
  targetCouponInfo: {},      // 当前选中的作为筛选条件的券
  promotionLabels: [],       // 优惠活动类+商品信息类筛选标签
  dynamicLabel: {},          // 筛选栏机动位标签
  filterHighWeightGoods: {}, // 筛选后高优商品信息
  /* --- 购物车筛选栏end  */
  showShareDraw: {
    show: false,
    params: null,
  }, // 控制商品行分享弹窗及传参
  asyncComponentLoadedStatus: {
    addForCouponIsLoaded: false,
    cartShareDrawIsLoaded: false,
    cartFullGiftsDrawIsLoaded: false,
    cartAddBuyDrawIsLoaded: false,
    cartBuyGiftDrawIsLoaded: false,
    cartEstimatedDetailDrawIsLoaded: false,
    cartClubGiftDrawIsLoaded: false,
    cartEmailConfirmDrawIsLoaded: false,
    cartAccountRegisterDrawIsLoaded: false,
    cartRegisterFailedDrawIsLoaded: false,
    canceledOrderProductListIsLoaded: false,
    cartFloorDrawerIsLoaded: false,
    cartPromotionInterceptionIsLoaded: false,
  },
  discount: false, // 是否展示折扣，金额动画结束后再修改
  titleNavStyleFromCcc: {}, // ccc配置的titleNav样式
  taxInclusiveTip: '', // 包税信息
  freeShippingAddOnType: '', // 免邮凑单类型平台级或mall级 platform或者mall_code
  isMoreMallFreeShippingAddOn: false, // 当前免邮凑单的是否是多mall的
  realtimeBehaviorLabels: [], // 实时用户行为标签
  hasShowShareGuide: false, // 是否展示过分享引导
  bottomActivityConfig: { // 底部活动更新需要等待3种数据都准备好
    couponIsReady: false,
    promotionIsReady: false,
    freeshipIsReady: false,
  },
  paymentFrontShowForCartIndexInfo: {}, //查询支付分期前置文案信息
  unPaidOrderInfo: {}, //待支付订单信息
  // 购物车使用bff接口开关
  useBffApi: {
  },
  inducementInfoList: [], // 原始利诱点信息
  inducementSortTypes: [], // 利诱点类型排序，每显示一个从最前面移到最后
  inducementSortList: [], // 利诱点信息排序，每次进入购物车根据inducementSortTypes排一次序
  showIncentiveProductListDialog: false, // 展示利诱点商品列表弹窗
  showIncentivePopover: false, // 展示利诱点popover
  incentivePopoverSwitchTimer: null, // 利诱点popover switch定时器
  incentivePopoverKeepTimer: null, // 利诱点popover keep定时器
  showIncentiveTimes: 0, // 展示利诱点次数 max is 3
  incentivePopoverContent: {}, // 利诱点popover内容
  incentiveItemIds: [], // 利诱点商品id
  country_id: '', // 国家id
  countryIdMap: new Map(), // 国家列表
  renderPaypalBtnStatus: -1, //paypal按钮渲染状态 -1:未渲染 0:渲染失败 1:渲染成功 
  channelSessionParams: null, //渠道会话参数
  /* -- 购物车quickship标签信息 -- */ 
  cartQsTagInfo: {
    isShowNewStyle: false,  // 是否为新样式（商品行）
    text: '',               // quickship标题文案（标签前半部分）
    qsTimelinessInfo: {     // quickship时效信息
      isLocationTop: false, // 是否展示在购物车顶部
      tip: '',              // quickship时效文案（标签后半部分）
    },
    qsMallList: {}, // 商品维度的 QS 数据
    last_quickship_tag: '', // 发货时效最晚的 QS 信息
    isJp: false, // 是否为日本收货地址
  },
  canceledOrderInfo: {}, // 取消订单信息
  showCanceledOrderDraw: false,
  canceledOrderTipTimer: null,
  showCanceledOrderSwitch: true,
  isNewUserOfTsp: false, // 是否新客
  newUserType: null, // 新客类型，1-isNewUser为true且注册7天内；2-isNewUser为true且超365历史已购，365未购；3-isNewUser为true但未命中1和2
  isFreeReturn: false,
  mixSelectedCartIds: [], // promo弹窗缓存的购物车Id
  promotionCouponMix: null, // 底部活动和券融合数据
  canShowPromoGuide: false, // 是否满足展示新人指引的条件
  promoHubVisible: false, // 活动和券弹窗状态
  monitorUserInteraction: null,
  showRecommendGuideA: false,
  recommendGoods: [], // 推荐商品信息，在引导至推荐a方案的气泡中显示
  estimatedAddonTips: [], // estimated price drawer add on tips
  isFetchingCouponInfo: false, // is coupon helper data fetching
  isShowCartFloorDrawer: false, // 是否展示购物车楼层弹窗
  popupCartLure: null, // 购物车楼层数据
  notShowCartFloorDrawer: false, // 单利诱商品不展示购物车楼层弹窗
  lowinventoryItemInfo: {
    id: '', // first Oos item id
    imgItemId: '', // second Oos item img url
    itemIds: [], // all Oos item ids
    languageKey: '',
  },
  mallCartInfo: {},
  // BFF 接口返回的购物车超限拦截信息
  interceptResultInfo: {},
  showPromotionInterceptionDialog: false,
}

export default state
